ACC.product = {

	_autoload: [
		"bindToAddToCartForm",
		"enableStorePickupButton",
		"enableVariantSelectors",
		"bindAmountContainerPosition",
		"bindChangeAmountOnProductImage",
		"bindPreviewLinks"
	],

	isAjaxCallRunning: false,

	enableAddToCartButton: function () {
		$('.js-enable-btn').each(function () {
			if (!($(this).hasClass('outOfStock') || $(this).hasClass('out-of-stock'))) {
				$(this).prop("disabled", false);
			}
		});
	},

	enableVariantSelectors: function () {
		$('.variant-select').prop("disabled", false);
	},

	bindAmountContainerPosition: function () {
		var self = this;
		// repositioning of amount-containers inside product-carousels is handled separately in ACC.productcarousel
		var notInsideProductCarouselFilter = function (index, element) {
			return !$(element).closest('.js-productcarousel').length
		};
		var $allAmountContainers = $('.js-amount-overlay').filter(notInsideProductCarouselFilter);

		// initially calculate layout for amount overlay
		$allAmountContainers.each(self.setAmountContainerLayoutAndStyle);

		// recalculate layout on some browser events
		$(window).on("resize.amountContainerPosition orientationchange.amountContainerPosition load.amountContainerPosition", function (event) {
			$allAmountContainers.each(self.setAmountContainerLayoutAndStyle);
		});
	},

	unbindAmountContainerPosition: function () {
		$(window).off(".amountContainerPosition");
	},

	bindChangeAmountOnProductImage: function () {
		var self = this;
		var selector = {
			inputField: '.product-item .add_to_cart_form input.js-price',
			productTile: '.product-item',
			amountContainer: '.js-amount-overlay'
		};

		function showAmountOnImageHandler(event) {
			var $input = $(event.currentTarget),
				currentVal = $input.val();
			if (isNaN(Number(currentVal))) return true;
			var $productItem = $input.closest(selector.productTile),
				$container = $productItem.find(selector.amountContainer);
			if (currentVal.length) {
				$container.html(Number(currentVal).toFixed(2));
				$container.each(self.setAmountContainerLayoutAndStyle);
			}
			else
				$container.html('');
			return true;
		}

		$(document).on('input.amountOnImage keyup.amountOnImage blur.amountOnImage change.amountOnImage', selector.inputField, showAmountOnImageHandler);
	},

	unbindChangeAmountOnProductImage: function () {
		$(document).off('.amountOnImage');
	},


	bindToAddToCartForm: function () {
		var $addToCartForm = $('.add_to_cart_form');
		$addToCartForm.ajaxForm({
			beforeSubmit: ACC.product.beforeSubmitHandler,
			success: ACC.product.successHandler,
			complete: ACC.product.completeHandler
		});
	},

	beforeSubmitHandler: function (arr, $form, options) {
		if (!ACC.product.checkOnlyOneRequest()) return false;
		if (!ACC.product.checkAmountIsSet($form)) return false;
		// set running flag as last or before returning true
		ACC.product.isAjaxCallRunning = true;
	},

	successHandler: function (addToCartResultData, statusText, xhr, $form) {
		ACC.config.isOrderWithDigitalFulfillment = addToCartResultData.cartData.isOrderWithDigitalFulfillment;
		ACC.product.displayAddToCartPopup(addToCartResultData);
		ACC.product.trackAddToCart(addToCartResultData, $form);
		if (typeof ACC.minicart.updateMiniCartDisplay === 'function') {
			ACC.minicart.updateMiniCartDisplay();
		}
	},

	completeHandler: function (xhr, statusText) {
		ACC.product.isAjaxCallRunning = false;
	},

	checkOnlyOneRequest: function () {
		return !ACC.product.isAjaxCallRunning;
	},

	checkAmountIsSet: function ($addToCartForm) {
		var $amountField = $addToCartForm.find('input[name="value"]');
		if (!$amountField.length) return true; // early exit, if no amount field is present (e.g. wrapping product)
		var $addToCartBtn = $addToCartForm.find('.js-enable-btn');
		// if amount is empty cancel submit and show info
		return ACC.configuratorValidation.validateAmount($amountField.val(), $addToCartBtn);
	},

	bindToAddToCartStorePickUpForm: function () {
		var addToCartStorePickUpForm = $('#colorbox #add_to_cart_storepickup_form');
		addToCartStorePickUpForm.ajaxForm({success: ACC.product.successHandler});
	},

	enableStorePickupButton: function () {
		$('.js-pickup-in-store-button').prop("disabled", false);
	},

	displayAddToCartPopup: function (cartResult) {
		$('#addToCartLayer').remove();
		var titleHeader = $('.addToCartTitle').first().html();

		ACC.colorbox.open(titleHeader, {
			html: cartResult.addToCartLayer,
			width: "460px"
		});

	},

	trackAddToCart: function (cartResult, formElement) {
		var productCode = $('[name=productCodePost]', formElement).val();
		var quantityField = $('[name=qty]', formElement).val();

		var quantity = 1;
		if (quantityField != undefined) {
			quantity = quantityField;
		}

		var cartAnalyticsData = cartResult.cartAnalyticsData;

		var cartData = {
			"cartCode": cartAnalyticsData.cartCode,
			"productCode": productCode, "quantity": quantity,
			"productPrice": cartAnalyticsData.productPostPrice,
			"productName": cartAnalyticsData.productName
		};
		ACC.track.trackAddToCart(productCode, quantity, cartData);
	},

	bindPreviewLinks: function () {
		var config = $.extend({}, ACC.colorbox.config, {
			photo: true,
			title: false
		});
		$(".js-preview-link").colorbox($.extend({}, config, {className: 'preview-popup'}));
		$(".js-preview-link-rounded").colorbox($.extend({}, config, {className: 'preview-popup preview-popup--rounded'}));
	},

	unbindPreviewLinks: function ($link) {
		if ($link === undefined) {
			$link = $(".js-preview-link,.js-preview-link-rounded");
		}
		$link.removeClass("cboxElement");
	},

	/**
	 * set amount-container-overlay based on image width and configuration
	 * - this method should be invoked, after product-image-size has changed (e.g. window.resize, window.orientationchange, slickslider.init, ...)
	 * @param index
	 * @param element
	 */
	setAmountContainerLayoutAndStyle: function(index, element) {
		var $container = $(element),
			designConfig = JSON.parse($container.next("script").text()),
			$containerParent = $container.parent(),
			referenceWidth = $containerParent.find("img").first().width(),
			$amount = $container.text().trim();

		// if amount is set show it on the card image
		if($amount.length > 0) {
			$container.removeClass('hidden');
		}

		// copied logic from ACC.configuratorActions.updateAmountField()
		var amountX = referenceWidth * designConfig.amountX,
			amountY = referenceWidth * designConfig.amountY,
			amountWidth = referenceWidth * designConfig.amountW,
			amountHeight = referenceWidth * designConfig.amountH,
			amountFontSize = amountHeight * designConfig.amountFontScale,
			amountAlignment = designConfig.amountAlignment.code.toLowerCase();

		// set layout and style
		$container.width(amountWidth).height(amountHeight).css({
			'left': amountX + 'px',
			'top': amountY + 'px',
			'font-size': amountFontSize + 'px',
			'line-height': amountHeight + 'px',
			'font-family': designConfig.amountFont,
			'color': designConfig.amountColor,
			'text-align': amountAlignment
		});
	}
};

$(document).ready(function () {
	ACC.product.enableAddToCartButton();
});
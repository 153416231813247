ACC.configuratorActions = {
	dropBackgroundImage: function (e, $ref) {
		var dataTransfer = e.originalEvent.dataTransfer;

		$ref.removeClass('highlighted');

		if (dataTransfer && dataTransfer.files.length) {
			e.preventDefault();
			e.stopPropagation();

			$.each(dataTransfer.files, function (i, file) {
				var fileReader = new FileReader();

				if (!ACC.configuratorValidation.validateFileFormat(file, $(ACC.configuratorConfig.selectors.uploadBackgroundBtn))) {
					return false;
				}

				fileReader.onloadend = function (e) {
					ACC.configuratorActions.replaceBackgroundImage(e);
				};

				ACC.configuratorValidation.validateBackgroundImageFileSize(file, fileReader);
			});
		} else {
			e.preventDefault();
			e.stopPropagation();

			if ($('.cr-boundary').length) {
				$('.cr-boundary').removeClass('faded-out');
			}
		}
	},

	dropLogo: function (e, $ref) {
		var dataTransfer = e.originalEvent.dataTransfer;

		$ref.removeClass('highlighted');

		if (dataTransfer && dataTransfer.files.length) {
			e.preventDefault();
			e.stopPropagation();

			$.each(dataTransfer.files, function (i, file) {
				var fileReader = new FileReader();

				if (!ACC.configuratorValidation.validateFileFormat(file, $(ACC.configuratorConfig.selectors.uploadLogoBtn))) {
					return false;
				}

				fileReader.onloadend = function (e) {
					ACC.configuratorActions.replaceLogo(e);
				};

				ACC.configuratorValidation.validateLogoFileSize(file, fileReader);
			});
		} else {
			e.preventDefault();
			e.stopPropagation();

			if ($('.cr-boundary').length) {
				$('.cr-boundary').removeClass('faded-out');
			}
		}
	},

	uploadBackgroundImage: function () {
		var chooseBgImageBtn = document.getElementById('chooseBackgroundBtn');
		var fileReader = new FileReader();

		if (!ACC.configuratorValidation.validateFileFormat(chooseBgImageBtn.files[0], $(ACC.configuratorConfig.selectors.uploadBackgroundBtn))) {
			return false;
		}

		fileReader.onloadend = function (e) {
			ACC.configuratorActions.replaceBackgroundImage(e);
		};

		if (chooseBgImageBtn.files[0]) {
			ACC.configuratorValidation.validateBackgroundImageFileSize(chooseBgImageBtn.files[0], fileReader);
		}
	},

	uploadLogo: function () {
		var chooseLogoBtn = document.getElementById('chooseLogoBtn');
		var fileReader = new FileReader();

		if (!ACC.configuratorValidation.validateFileFormat(chooseLogoBtn.files[0], $(ACC.configuratorConfig.selectors.uploadLogoBtn))) {
			return false;
		}

		fileReader.onloadend = function (e) {
			ACC.configuratorActions.replaceLogo(e);
			$(ACC.configuratorConfig.selectors.logoWrapper).removeClass('faded-out');
		}

		if (chooseLogoBtn.files[0]) {
			ACC.configuratorValidation.validateLogoFileSize(chooseLogoBtn.files[0], fileReader);
		}
	},

	replaceBackgroundImage: function (e) {
		var image = new Image();

		// show loading spinner
		ACC.loading.blockUI();

		image.onload = function () {
			var $uploadBackgroundBtn = $(ACC.configuratorConfig.selectors.uploadBackgroundBtn);

			ACC.configurator.destroyCroppie();
			ACC.configurator.initCroppie(image.src);
			ACC.configurator.properties.tempFormDataObject.backgroundImage.src = image.src;

			$(ACC.configuratorConfig.selectors.croppieViewport).css({'width': ACC.configuratorConfig.properties.configuratorMaskWidth, 'height': ACC.configuratorConfig.properties.configuratorMaskHeight});

			ACC.configuratorActions.showCheckIcon($uploadBackgroundBtn);
			$(ACC.configuratorConfig.selectors.sliderWrap).removeClass('disabled');

			// update width of backgroundImage
			ACC.configurator.properties.tempFormDataObject.backgroundImage.originalWidth = image.naturalWidth;

			if ($('.cr-boundary').length) {
				$('.cr-boundary').removeClass('faded-out');
			}

			// correct the positions for logo and amount (can be wrong after browser zoom)
			// hide loading spinner
			ACC.loading.unBlockUI();
		};

		// check if file was uploaded (result) or selected from the sample designs (src).
		// The src needs to be converted to base64.
		if (e.currentTarget.src === undefined) {
			image.src = e.currentTarget.result;
		}
		else {
			// set base64 converted image as src for backgroundImage
			ACC.configuratorActions.convertSrcToBase64(e.currentTarget.src, image);
		}

	},

	convertSrcToBase64: function (src, imageObject) {
		var dataURL;
		var tempImageObject = new Image();

		tempImageObject.src = src;
		tempImageObject.onload = function () {
			var canvas = document.createElement('CANVAS');
			var ctx = canvas.getContext('2d');
			canvas.height = this.naturalHeight;
			canvas.width = this.naturalWidth;
			ctx.drawImage(this, 0, 0);
			dataURL = canvas.toDataURL();

			imageObject.src = dataURL;
		};

		// if image cannot be loaded correctly => set src to empty string
		tempImageObject.onerror = function () {
			imageObject.src = "";
		}
	},

	replaceLogo: function (e) {
		var image = new Image();

		// show loading spinner
		ACC.loading.blockUI();

		image.onload = function () {
			var $uploadLogoBtn = $(ACC.configuratorConfig.selectors.uploadLogoBtn);

			ACC.configurator.destroyLogoCroppie();
			ACC.configurator.properties.tempFormDataObject.logo.src = image.src;

			$(ACC.configuratorConfig.selectors.logoImgElement).attr('src', image.src);

			ACC.configurator.initLogoCroppie(image);

			ACC.configuratorActions.showCheckIcon($uploadLogoBtn);

			// configuration was changed
			ACC.configurator.properties.configurationChanged = true;


			$(ACC.configuratorConfig.selectors.logoWrapper).removeClass('faded-out');

			// hide loading spinner
			ACC.loading.unBlockUI();
		};

		image.src = e.target.result;
	},

	loadConfiguration: function (productCode, entryNumber, orderCode, orderEntryNumber, amount) {
		var configUrl, settings;

		// check if data attributes exist and are not empty
		if (ACC.configurator.isConfiguratorForCartProduct()) {
			// restore card from cart page
			configUrl = ACC.configuratorConfig.urls.restoreConfig;
			settings = {
				ajaxUrl: configUrl,
				ajaxSettings: {
					entryNumber: entryNumber
				},
				addToCartLabel: $(ACC.configuratorConfig.selectors.addToCartBtn).data('labelChangeConfig'),
				configurationChanged: false,
				configurationRestored: true,
				backgroundImageReplaced: false,
				logoReplaced: false,
				showMyDesignsLink: true
			};

			ACC.configuratorActions.restoreConfiguredCard(settings);
		}
		else if (ACC.configurator.isConfiguratorForMyDesignsProduct()) {
			// restore card from myDesigns page
			configUrl = ACC.configuratorConfig.urls.restoreOrderEntryConfig;
			settings = {
				ajaxUrl: configUrl,
				ajaxSettings: {
					productCode: productCode,
					orderCode: orderCode,
					entryNumber: orderEntryNumber,
					configuredAmount: amount
				},
				addToCartLabel: $(ACC.configuratorConfig.selectors.addToCartBtn).data('labelAddToCart'),
				configurationChanged: true,
				configurationRestored: false,
				backgroundImageReplaced: true,
				logoReplaced: true,
				showMyDesignsLink: false
			};

			ACC.configuratorActions.restoreConfiguredCard(settings);
		}
		else if (ACC.configurator.isConfiguratorForOrderEntryProduct()) {
			// restore card from order history edit entry page
			configUrl = ACC.configuratorConfig.urls.restoreOrderEntryConfig;
			settings = {
				ajaxUrl: configUrl,
				ajaxSettings: {
					orderCode: orderCode,
					entryNumber: orderEntryNumber
				},
				addToCartLabel: $(ACC.configuratorConfig.selectors.addToCartBtn).data('labelChangeConfig'),
				configurationChanged: false,
				configurationRestored: true,
				backgroundImageReplaced: false,
				logoReplaced: false,
				showMyDesignsLink: true
			};

			ACC.configuratorActions.restoreConfiguredCard(settings);
		}
		else {
			// initial load from category or details page
			configUrl = ACC.configuratorConfig.urls.initialConfig;
			settings = {
				ajaxUrl: configUrl,
				ajaxSettings: {
					productCode: productCode,
					configuredAmount: amount
				},
				addToCartLabel: $(ACC.configuratorConfig.selectors.addToCartBtn).data('labelAddToCart'),
				configurationChanged: false,
				configurationRestored: false,
				backgroundImageReplaced: false,
				logoReplaced: false,
				showMyDesignsLink: true
			};
			ACC.configuratorActions.loadInititalConfig(settings);
		}
	},

	// load an initial config, nothing has to be restored
	loadInititalConfig: function (settings) {
		// show loading spinner when loading the config
		ACC.loading.blockUI();

		$.get(settings.ajaxUrl, settings.ajaxSettings).done(function (data) {
			ACC.configurator.properties.responseData = data;

			var configuratorMode = 'simple';
			if (data.configSettings.DESIGN.isBackgroundCustomizable) {
				configuratorMode = 'advanced';
			}
			// set configurator mode and show settings section
			$(ACC.configuratorConfig.selectors.popupID).addClass(configuratorMode);
			$('.card-configurator-settings--invisible').removeClass('card-configurator-settings--invisible');

			// set mode/configurationRestored and reset configurationChanged
			ACC.configurator.properties.mode = configuratorMode;
			ACC.configurator.properties.configurationChanged = settings.configurationChanged;
			ACC.configurator.properties.configurationRestored = settings.configurationRestored;
			ACC.configurator.properties.backgroundImageReplaced = settings.backgroundImageReplaced;
			ACC.configurator.properties.logoReplaced = settings.logoReplaced;

			// set image mask, set styles for the specific mode
			if (ACC.configurator.properties.mode == 'simple') {
				$(ACC.configuratorConfig.selectors.imageMask).attr('src', data.configSettings.DESIGN.productBackground.url);
				ACC.configurator.properties.tempFormDataObject.logo.src = ''
			}
			else {
				$(ACC.configuratorConfig.selectors.imageMask).attr('src', data.configSettings.DESIGN.designOverlayPreview.url);
				ACC.configurator.properties.tempFormDataObject.logo.src= '';
				ACC.configurator.properties.tempFormDataObject.backgroundImage.src= ''
			}

			// set amount for configurator
			$(ACC.configuratorConfig.selectors.configuratorAmount).text(data.configInfos.AMOUNT.configuredAmountFormatted);

			ACC.configurator.initCroppie("");
			ACC.configurator.initLogoCroppie("");

			// set min height and width for background image validation
			ACC.configuratorConfig.properties.backgroundImageMinHeight = data.configSettings.DESIGN.targetH;
			ACC.configuratorConfig.properties.backgroundImageMinWidth = data.configSettings.DESIGN.targetW;

			// set height and width for the croppie plugin mask
			ACC.configuratorConfig.properties.configuratorMaskHeight = data.configSettings.DESIGN.targetH;
			ACC.configuratorConfig.properties.configuratorMaskWidth = data.configSettings.DESIGN.targetW;
			$(ACC.configuratorConfig.selectors.croppieViewport).css({'width': ACC.configuratorConfig.properties.configuratorMaskWidth, 'height': ACC.configuratorConfig.properties.configuratorMaskHeight});

			// set amount incl. position and size
			ACC.configuratorActions.updateAmountField(data);

			// set size and position of logo field
			ACC.configuratorActions.setLogoFieldPosition(data);

			ACC.configuratorActions.setAddToCartBtnLabel(settings.addToCartLabel);

			// show mydesigns link
			$(ACC.configuratorConfig.selectors.cardConfiguratorMydesigns).removeClass('hidden');

			ACC.configuratorActions.resizeColorbox();

			// hide loading spinner
			ACC.loading.unBlockUI();
		});
	},

	// restore a configured card
	restoreConfiguredCard: function (settings) {
		// set default values
		ACC.configurator.properties.restoringBackgroundImage = false;
		ACC.configurator.properties.restoringLogo = false;

		// show loading spinner when images are restored
		ACC.loading.blockUI();

		$.get(settings.ajaxUrl, settings.ajaxSettings).done(function (data) {
			ACC.configurator.properties.responseData = data;

			var configuratorMode = 'simple';
			if (data.configSettings.DESIGN.isBackgroundCustomizable) {
				configuratorMode = 'advanced';
			}
			// set configurator mode and show settings section
			$(ACC.configuratorConfig.selectors.popupID).addClass(configuratorMode);
			$('.card-configurator-settings--invisible').removeClass('card-configurator-settings--invisible');

			// set mode/configurationRestored and reset configurationChanged
			ACC.configurator.properties.mode = configuratorMode;
			ACC.configurator.properties.configurationChanged = settings.configurationChanged;
			ACC.configurator.properties.configurationRestored = settings.configurationRestored;
			ACC.configurator.properties.backgroundImageReplaced = settings.backgroundImageReplaced;
			ACC.configurator.properties.logoReplaced = settings.logoReplaced;
			ACC.configurator.properties.configurationPartiallyRestored = false;

			// set image mask, set styles for the specific mode
			if (ACC.configurator.properties.mode == 'simple') {
				ACC.configurator.properties.restoringLogo = true;

				ACC.configuratorActions.restoreLogo(data);

				$(ACC.configuratorConfig.selectors.imageMask).attr('src', data.configSettings.DESIGN.productBackground.url);

				ACC.configurator.initCroppie("");
				ACC.configurator.initLogoCroppie("");

				// set min height and width for background image validation
				ACC.configuratorConfig.properties.backgroundImageMinHeight = data.configSettings.DESIGN.targetH;
				ACC.configuratorConfig.properties.backgroundImageMinWidth = data.configSettings.DESIGN.targetW;

				// set height and width for the croppie plugin mask
				ACC.configuratorConfig.properties.configuratorMaskHeight = data.configSettings.DESIGN.targetH;
				ACC.configuratorConfig.properties.configuratorMaskWidth = data.configSettings.DESIGN.targetW;
				$(ACC.configuratorConfig.selectors.croppieViewport).css({'width': ACC.configuratorConfig.properties.configuratorMaskWidth, 'height': ACC.configuratorConfig.properties.configuratorMaskHeight});

				// set amount incl. position and size
				ACC.configuratorActions.updateAmountField(data);

				// set size and position of logo field
				ACC.configuratorActions.setLogoFieldPosition(data);
			}
			else {
				ACC.configurator.properties.restoringBackgroundImage = true;
				ACC.configurator.properties.restoringLogo = true;

				ACC.configuratorActions.restoreBackgroundImage(data);
				ACC.configuratorActions.restoreLogo(data);
				// update tempFormDataObject
				ACC.configurator.properties.tempFormDataObject.showCardValue = data.configInfos.DESIGN.amountVisible;
			}

			if (!ACC.configurator.properties.restoringBackgroundImage && !ACC.configurator.properties.restoringLogo) {
				ACC.configuratorActions.resizeColorbox();
				// hide loading spinner
				ACC.loading.unBlockUI();
			}

			// set amount checkbox
			$(ACC.configuratorConfig.selectors.checkboxShowValue).prop("checked", data.configInfos.DESIGN.amountVisible).trigger("change");

			ACC.configuratorActions.setAddToCartBtnLabel(settings.addToCartLabel);

			// show mydesigns link
			ACC.configuratorActions.setLogoFieldPosition(data);

			ACC.configuratorActions.resizeColorbox();
		})
	},

	restoreBackgroundImage: function (data) {
		// set image mask for background image
		$(ACC.configuratorConfig.selectors.imageMask).attr('src', data.configSettings.DESIGN.designOverlayPreview.url);

		// check if design is defined, otherwise cancel
		if (data.configInfos.DESIGN.design === undefined) {
			ACC.configurator.properties.restoringBackgroundImage = false;
			ACC.configurator.properties.configurationPartiallyRestored = true;
			ACC.configurator.initCroppie("");
			return false;
		}

		var backgroundImageUrl = ACC.config.encodedContextPath + data.configInfos.DESIGN.design.downloadUrl;

		var backgroundImage = new Image();

		// in order to use the caching for the restored image, the file is converted to base64 first (with canvas).

		ACC.configuratorActions.convertSrcToBase64(backgroundImageUrl, backgroundImage);

		// if backgroundImage cannot be loaded correctly
		backgroundImage.onerror = function () {
			// default initialization of croppie plugin
			ACC.configurator.initCroppie("");

			// set min height and width for background image validation
			ACC.configuratorConfig.properties.backgroundImageMinHeight = data.configSettings.DESIGN.targetH;
			ACC.configuratorConfig.properties.backgroundImageMinWidth = data.configSettings.DESIGN.targetW;

			// set height and width for the croppie plugin mask
			ACC.configuratorConfig.properties.configuratorMaskHeight = data.configSettings.DESIGN.targetH;
			ACC.configuratorConfig.properties.configuratorMaskWidth = data.configSettings.DESIGN.targetW;
			$(ACC.configuratorConfig.selectors.croppieViewport).css({'width': ACC.configuratorConfig.properties.configuratorMaskWidth, 'height': ACC.configuratorConfig.properties.configuratorMaskHeight});

			// set amount incl. position and size
			ACC.configuratorActions.updateAmountField(data);

			// set size and position of logo field
			ACC.configuratorActions.setLogoFieldPosition(data);

			// restoring backgroundImage done
			ACC.configurator.properties.restoringBackgroundImage = false;
			ACC.configurator.properties.configurationPartiallyRestored = true;

			// if logo isn't restored
			if (!ACC.configurator.properties.restoringLogo) {
				ACC.configuratorActions.resizeColorbox();
				// hide loading spinner
				ACC.loading.unBlockUI();
			}
		};

		backgroundImage.onload = function () {
			var backgroundOriginalImageWidth = backgroundImage.naturalWidth;

			ACC.configurator.initCroppie(backgroundImage.src);

			// set min height and width for background image validation
			ACC.configuratorConfig.properties.backgroundImageMinHeight = data.configSettings.DESIGN.targetH;
			ACC.configuratorConfig.properties.backgroundImageMinWidth = data.configSettings.DESIGN.targetW;

			// set height and width for the croppie plugin mask
			ACC.configuratorConfig.properties.configuratorMaskHeight = data.configSettings.DESIGN.targetH;
			ACC.configuratorConfig.properties.configuratorMaskWidth = data.configSettings.DESIGN.targetW;
			$(ACC.configuratorConfig.selectors.croppieViewport).css({'width': ACC.configuratorConfig.properties.configuratorMaskWidth, 'height': ACC.configuratorConfig.properties.configuratorMaskHeight});

			ACC.configurator.properties.tempFormDataObject.backgroundImage.src = backgroundImage.src;
			ACC.configurator.properties.tempFormDataObject.backgroundImage.originalWidth = backgroundOriginalImageWidth;

			// set amount incl. position and size
			ACC.configuratorActions.updateAmountField(data);

			// set size and position of logo field
			ACC.configuratorActions.setLogoFieldPosition(data);

			// restoring backgroundImage done
			ACC.configurator.properties.restoringBackgroundImage = false;

			ACC.configuratorActions.showCheckIcon($(ACC.configuratorConfig.selectors.uploadBackgroundBtn));

			// if logo isn't restored
			if (!ACC.configurator.properties.restoringLogo) {
				ACC.configuratorActions.resizeColorbox();
				// hide loading spinner
				ACC.loading.unBlockUI();
			}
		};
	},

	restoreLogo: function (data) {
		// check if logo is defined, otherwise cancel
		if (data.configInfos.DESIGN.logo === undefined) {
			ACC.configurator.properties.restoringLogo = false;
			ACC.configurator.properties.configurationPartiallyRestored = true;
			return false;
		}

		var logoUrl = ACC.config.encodedContextPath + data.configInfos.DESIGN.logo.downloadUrl;
		var logo = new Image();
		ACC.configuratorActions.convertSrcToBase64(logoUrl, logo);

		// if logo cannot be loaded correctly
		logo.onerror = function () {
			// restoring logo done
			ACC.configurator.properties.restoringLogo = false;
			ACC.configurator.properties.configurationPartiallyRestored = true;

			// if backgroundImage isn't restored
			if (!ACC.configurator.properties.restoringBackgroundImage) {
				// hide loading spinner
				ACC.configuratorActions.resizeColorbox();
				ACC.loading.unBlockUI();
			}
		};

		logo.onload = function () {
			$(ACC.configuratorConfig.selectors.logoImgElement).attr('src', logo.src);
			ACC.configurator.properties.tempFormDataObject.logo.src = logo.src;
			$(ACC.configuratorConfig.selectors.logoWrapper).removeClass('faded-out');
			ACC.configurator.destroyLogoCroppie();
			ACC.configurator.initLogoCroppie(logo);
			// restoring logo done
			ACC.configurator.properties.restoringLogo = false;

			ACC.configuratorActions.showCheckIcon($(ACC.configuratorConfig.selectors.uploadLogoBtn));

			// if backgroundImage isn't restored
			if (!ACC.configurator.properties.restoringBackgroundImage) {
				ACC.configuratorActions.resizeColorbox();
				// hide loading spinner
				ACC.loading.unBlockUI();
			}
		};
	},

	prepareFormData: function (tempFormDataObject) {
		return new Promise(function (resolve, reject) {
			var promises = [];
			var formDataAddToCart = new FormData(ACC.configurator.properties.$configuratorCurrentForm.get(0));
			formDataAddToCart.append("configurationsKeyValueMap['DESIGN']['qualifier']", "design");
			formDataAddToCart.append("configurationsKeyValueMap['DESIGN']['amountVisible']", tempFormDataObject.showCardValue);

			//crop Background
			if (ACC.configurator.properties.mode == 'advanced') {
				var backgroundImagePromise = ACC.configuratorActions.cropImage(ACC.configuratorConfig.selectors.droppableArea).then(function (croppedImageSrc) {
					var blobCroppedImage = ACC.configuratorActions.dataURItoBlob(ACC.configurator.properties.backgroundImageReplaced ? croppedImageSrc : "");


					if (blobCroppedImage !== false) {
						formDataAddToCart.append("configurationsKeyValueMap['DESIGN']['design']", blobCroppedImage, "backgroundImage");
					}
				}).catch(function (error) {
					reject(error);
				})
				promises.push(backgroundImagePromise)
			}

			//crop Logo
			var logoPromise = ACC.configuratorActions.cropImage(ACC.configuratorConfig.selectors.logoCroppieArea).then(function (croppedLogoSrc) {
				var croppedLogoSrc = ACC.configuratorActions.dataURItoBlob(croppedLogoSrc);
				if (croppedLogoSrc !== false) {
					formDataAddToCart.append("configurationsKeyValueMap['DESIGN']['logo']", croppedLogoSrc, "logo");
				}
			}).catch(function (error) {
				reject(error);
			})
			promises.push(logoPromise)


			//only return if all Promises are fullfilled
			Promise.all(promises).then(function () {
				resolve(formDataAddToCart);
			})
				.catch(function (error) {
					reject(error);
				});
		})
	},

	dataURItoBlob: function (dataURI) {
		// convert base64/URLEncoded data component to raw binary data held in a string
		var byteString;

		if (dataURI === '') {
			return false;
		}

		if (dataURI.split(',')[0].indexOf('base64') >= 0)
		byteString = atob(dataURI.split(',')[1]);
		else
			return false;

		// separate out the mime component
		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

		// write the bytes of the string to a typed array
		var ia = new Uint8Array(byteString.length);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		return new Blob([ia], {type: mimeString});
	},

	cropImage: function (croppieArea) {
		return new Promise(function(resolve, reject) {
			$(croppieArea).croppie('result', {
				backgroundColor : "#ffffff",
				type: 'canvas',
				size: 'original',
			}).then(function(resp) {
				resolve(resp);
			}).catch(function(error) {
				reject(error);
			});
		});
	},

	addConfigurationToCart: function (e) {
		var requestUrl = ACC.configurator.properties.$configuratorCurrentForm.attr('action');
		ACC.configuratorActions.prepareFormData(ACC.configurator.properties.tempFormDataObject).then(function (formData)
		{
		// check if attributes are missing (not when card was restored without any errors): if so, cancel addToCart action
		if (!ACC.configurator.properties.configurationRestored || ACC.configurator.properties.configurationPartiallyRestored) {
			if (!ACC.configuratorValidation.validateFormData()) {
				return false;
			}
		}

		var ajaxSettings = {
			url: requestUrl,
			type: 'POST',
			enctype: 'multipart/form-data',
			processData: false,
			contentType: false,
			data: formData,
			beforeSend: function () {
				// show loading spinner when configuration is added to the cart
				ACC.loading.blockUI();
			}
		};

		$.ajax(ajaxSettings)
			.done(function (data, textStatus, jqXHR) {
				ACC.loading.unBlockUI();

				if (ACC.configurator.properties.configurationRestored) {
					window.location.reload();
				}
				else {
					ACC.configurator.cleanupConfigurator();
					ACC.product.successHandler(data, textStatus, jqXHR, ACC.configurator.properties.$configuratorCurrentForm);
				}
			});

		});
	},

	hideAllCheckIcons: function () {
		$(ACC.configuratorConfig.selectors.popupID).find(ACC.configuratorConfig.selectors.checkIcon).addClass('faded-out');
	},

	showCheckIcon: function ($elements) {
		$elements.closest('.card-configurator-settings-section').find(ACC.configuratorConfig.selectors.checkIcon).removeClass('faded-out');
	},

	updateAmountField: function (data) {
		// set amount for configurator
		var $amountField = $(ACC.configuratorConfig.selectors.configuratorAmount);
		$amountField.text(data.configInfos.AMOUNT.configuredAmountFormatted);

		var amountFontSizeFactor = data.configSettings.DESIGN.amountFontScale;
		var amountHeight = ACC.configuratorConfig.properties.configuratorMaskWidth * data.configSettings.DESIGN.amountH;
		var amountWidth = ACC.configuratorConfig.properties.configuratorMaskWidth * data.configSettings.DESIGN.amountW;
		var amountX = ACC.configuratorConfig.properties.configuratorMaskWidth * data.configSettings.DESIGN.amountX;
		var amountY = ACC.configuratorConfig.properties.configuratorMaskWidth * data.configSettings.DESIGN.amountY;

		// calculate postion and size for amount field
		var imageMaskLeftPos = 0;
		var imageMaskTopPos = 0;

		if ($(ACC.configuratorConfig.selectors.croppieViewport).css('left') === '0px') {
			imageMaskLeftPos = ($(ACC.configuratorConfig.selectors.croppieViewport).outerWidth(true) - $(ACC.configuratorConfig.selectors.croppieViewport).outerWidth())/2;
			imageMaskTopPos = ($(ACC.configuratorConfig.selectors.croppieViewport).outerHeight(true) - $(ACC.configuratorConfig.selectors.croppieViewport).outerHeight())/2;

			$amountField.css({
				'margin-left': imageMaskLeftPos + amountX + 'px',
				'margin-top': imageMaskTopPos + amountY + 'px'

			});
		}

		$amountField.css({
			'line-height': amountHeight + 'px',
			'font-size': amountHeight * amountFontSizeFactor + 'px',
			'font-family': data.configSettings.DESIGN.amountFont,
			'color': data.configSettings.DESIGN.amountColor
		});
		$amountField.width(amountWidth);
		$amountField.height(amountHeight);

		$(ACC.configuratorConfig.selectors.cardValue).show();
	},

	setLogoFieldPosition: function (data) {
		// set position for logo field
		var $logoField = $(ACC.configuratorConfig.selectors.logoWrapper);
		var logoHeight = ACC.configuratorConfig.properties.configuratorMaskWidth * data.configSettings.DESIGN.logoH;
		var logoWidth = ACC.configuratorConfig.properties.configuratorMaskWidth * data.configSettings.DESIGN.logoW;
		var logoX = ACC.configuratorConfig.properties.configuratorMaskWidth * data.configSettings.DESIGN.logoX;
		var logoY = ACC.configuratorConfig.properties.configuratorMaskWidth * data.configSettings.DESIGN.logoY;

		// calculate position and size for logo field
		var imageMaskLeftPos = 0;
		var imageMaskTopPos = 0;

		if ($(ACC.configuratorConfig.selectors.croppieViewport).css('left') === '0px') {
			imageMaskLeftPos = ($(ACC.configuratorConfig.selectors.croppieViewport).outerWidth(true) - $(ACC.configuratorConfig.selectors.croppieViewport).outerWidth())/2;
			imageMaskTopPos = ($(ACC.configuratorConfig.selectors.croppieViewport).outerHeight(true) - $(ACC.configuratorConfig.selectors.croppieViewport).outerHeight())/2;

			$logoField.css({
				'margin-left': imageMaskLeftPos + logoX + 'px',
				'margin-top': imageMaskTopPos + logoY + 'px'
			});
		}

		// set width and height for logo wrapper
		$logoField.innerWidth(logoWidth);
		$logoField.innerHeight(logoHeight);

	},

	setAddToCartBtnLabel: function (label) {
		$(ACC.configuratorConfig.selectors.addToCartBtn).text(label);
	},

	resizeColorbox: function () {
		$(document).scrollTop(ACC.configurator.properties.scrollTop);

		$.colorbox.resize({
			width: '100%',
			innerHeight: $(ACC.configuratorConfig.selectors.popupID).height()
		});
	},
};
/*
* The loading handler uses the jQuery blockUI plugin for page blocking in case of longer ajax requests.
* */

ACC.loading = {
	_autoload: [
		"bindLoadingHandler"
	],

	properties: {
		blockUI: {
			message: '<div class="loading-spinner"><img class="loading-spinner__image" src="' + ACC.config.commonResourcePath + '/images/spinner.gif" />' + ACC.loadingText + '</div>',
			css: {
				border: 'none',
				padding: '15px 0'
			},
			baseZ: 5000
		}
	},

	bindLoadingHandler: function () {
		// in case of ajax errors => hide loading spinner
		$(document).ajaxError(function(){
			$.unblockUI();
		});
	},

	unBlockUI: function() {
		$.unblockUI();
	},

	blockUI: function () {
		$.blockUI(ACC.loading.properties.blockUI);
	}
};
ACC.checkout = {

	_autoload: [
		"bindCheckO",
		"bindDatepicker",
		"bindForms",
		"bindSavedPayments",
		"bindPopupMinimumOrderValue",
		"addNewCreditcardPopup",
		"bindOrderSubmit",
		"bindInvoiceSelectRadio",
		["bindToggleSenderReferenceNumber", $('#b2bgkShippingStepForm').length > 0]
	],

	bindInvoiceSelectRadio: function(){
		$(document).on("click", "#invoice", function (e) {
			$("#sendInvoiceByPost").prop("checked", true);
		});

		$(document).on("click", "#creditcard", function (e) {
			$("#sendInvoiceByMail").prop("checked", false);
			$("#sendInvoiceByPost").prop("checked", false);
		});

		$(document).on("click", "#prepayment", function (e) {
			$("#sendInvoiceByMail").prop("checked", false);
			$("#sendInvoiceByPost").prop("checked", false);
		});

	},

	bindForms: function () {

		$(document).on("click", "#addressSubmit", function (e) {
			e.preventDefault();
			$('#b2bgkAddressForm').submit();
		});

		$(document).on("click", "#deliveryMethodSubmit", function (e) {
			e.preventDefault();
			$('#selectDeliveryMethodForm').submit();
		});

		$(document).on("click", "#placeOrder", function (e) {
			e.preventDefault();
			$('#placeOrderForm').submit();
		})

	},

	bindOrderSubmit: function()
	{

		$(".submit-order").on('click', function(e)
		{
			e.preventDefault();

			var postData = $("#placeOrderForm").serializeArray();
			var ajaxUrl = $("#datatrans-form-container").data('url');

			$(".form__errors, .notification, .alert").remove();
			$('.alert--danger').addClass('d-none');

			$.ajax({
				url: ajaxUrl,
				dataType: "json",
				data: postData,
				type: "POST",
				success: function(json)
				{
					if (json.success)
					{
						if (json.datatransNeeded)
						{
							$('#datatrans-form-container').html(json.datatransForm);
							$('#datatrans-form-container form').submit();
						}
						else
						{
							$("#placeOrderForm").submit();
						}
					}
					else
					{
						console.log("submitOrder did not work: datatrans not succesful");
						// re-activate submit button
						reEnableOrderSubmitButton();
						showTermsCheckErrorMessage()
					}
				},
				error: function(response, status, error)
				{
					console.log("submitOrder did not work: " + error);
					// re-activate submit button
					reEnableOrderSubmitButton();
					showTermsCheckErrorMessage()
				}
			});
		});

		function reEnableOrderSubmitButton()
		{
			$('.submit-order').removeAttr('disabled').removeClass('cta__disabled');
		}

		function showTermsCheckErrorMessage()
		{
			var errorMessageOrderReasonTxt =  $("#errorMessageOrderReason").html();
			$( ".form-mandatory" ).eq(0).addClass( "has-error" );
			$( ".has-error" ).eq(0).append( '<div class="help-block"> <span id="termsCheck.errors">'+errorMessageOrderReasonTxt+'</span></div>' );

			var errorMessageThermsTxt =  $("#errorMessageTherms").html();
			$( ".form-mandatory" ).eq(1).addClass( "has-error" );
			$( ".has-error" ).eq(1).append( '<div class="help-block"> <span id="termsCheck.errors">'+errorMessageThermsTxt+'</span></div>' );
		}
	},

	//ueacs
	addNewCreditcardPopup: function () {

		var addNewCreditcardPopup;

		$(document).on('click.configurator', '.addCreditCard', function (e) {
			e.preventDefault();
			$('#addNewCreditcardPopup').popup({
				closebutton: true,
			});

			$('#addNewCreditcardPopup').popup('show');
			$('#addNewCreditcardPopup #cboxContent .configurator-closeBtn').last().remove();
			$('.popup_close').remove();
			addNewCreditcardPopup = true;
		});

		$(document).on('#addNewCreditcardPopup keyup.configurator', function (e) {
			if (e.keyCode === 27 && !addNewCreditcardPopup) {
				$('#addNewCreditcardPopup').popup({
					closebutton: true,
				});

				$('#addNewCreditcardPopup').popup('show');
				$('#addNewCreditcardPopup #cboxContent .configurator-closeBtn').last().remove();
				$('.popup_close').remove();
				addNewCreditcardPopup = true;
			}
			else
			{
				addNewCreditcardPopup = false;
			}
		});

	},

	bindSavedPayments: function () {
		$(document).on("click", ".js-saved-payments", function (e) {
			e.preventDefault();

			var title = $("#savedpaymentstitle").html();

			$.colorbox({
				href: "#savedpaymentsbody",
				inline: true,
				maxWidth: "100%",
				opacity: 0.7,
				//width:"320px",
				title: title,
				close: '<span class="glyphicon glyphicon-remove"></span>',
				onComplete: function () {
				}
			});
		})
	},

	bindCheckO: function () {
		var cartEntriesError = false;

		// Alternative checkout flows options
		$('.doFlowSelectedChange').change(function () {
			if ('multistep-pci' == $('#selectAltCheckoutFlow').val()) {
				$('#selectPciOption').show();
			}
			else {
				$('#selectPciOption').hide();

			}
		});


		$('.js-continue-shopping-button').click(function () {
			var checkoutUrl = $(this).data("continueShoppingUrl");
			window.location = checkoutUrl;
		});

		$('.js-create-quote-button').click(function () {
			$(this).prop("disabled", true);
			var createQuoteUrl = $(this).data("createQuoteUrl");
			window.location = createQuoteUrl;
		});


		$('.expressCheckoutButton').click(function () {
			document.getElementById("expressCheckoutCheckbox").checked = true;
		});

		$(document).on("input", ".confirmGuestEmail,.guestEmail", function () {

			var orginalEmail = $(".guestEmail").val();
			var confirmationEmail = $(".confirmGuestEmail").val();

			if (orginalEmail === confirmationEmail) {
				$(".guestCheckoutBtn").removeAttr("disabled");
			} else {
				$(".guestCheckoutBtn").attr("disabled", "disabled");
			}
		});

		$('.js-continue-checkout-button').click(function () {
			var checkoutUrl = $(this).data("checkoutUrl");


			var expressCheckoutObject = $('.express-checkout-checkbox');
			if (expressCheckoutObject.is(":checked")) {
				window.location = expressCheckoutObject.data("expressCheckoutUrl");
			}
			else {
				var flow = $('#selectAltCheckoutFlow').val();
				if (flow == undefined || flow == '' || flow == 'select-checkout') {
					// No alternate flow specified, fallback to default behaviour
					window.location = checkoutUrl;
				}
				else {
					// Fix multistep-pci flow
					if ('multistep-pci' == flow) {
						flow = 'multistep';
					}
					var pci = $('#selectPciOption').val();

					// Build up the redirect URL
					var redirectUrl = checkoutUrl + '/select-flow?flow=' + flow + '&pci=' + pci;
					window.location = redirectUrl;
				}
			}

			return false;
		});

	},

	bindDatepicker: function() {
		var $datepicker = $('.js-checkout-datepicker');

		// // init datepicker
		if($datepicker.length) {
			$datepicker.each(function () {
				var $ref = $(this);
				ACC.checkout.initDatepicker($ref);
			});
		}

		$datepicker.on('change', function() {
			var $ref = $(this);
			var localizedDate = $ref.val();

			// split date (format dd-mm-yy)
			var localizedDateArray = localizedDate.split('.');
			var localizedDateParts = {
				day: localizedDateArray[0],
				month: localizedDateArray[1],
				year: localizedDateArray[2]
			};

			// set the date to yy-mm-dd (for the BE)
			var $parent = $ref.parent();
			$parent.find('input[type=hidden]').val(localizedDateParts.year + '-' + localizedDateParts.month + '-' + localizedDateParts.day);

			// show remove btn
			$parent.find('.js-checkout-datepicker-remove-btn').removeClass('hidden');
		});

		// reset date
		$(document).on('click', '.js-checkout-datepicker-remove-btn', function(event) {
			event.preventDefault();
			var $removeBtn = $(event.currentTarget);
			$removeBtn.parent().find('input').val('');
			$removeBtn.addClass('hidden');
		});
	},

	initDatepicker: function($ref) {
		var shippingOffset = $ref.data('shippingOffset');

		// default date format dd.mm.yy, min Date: + shipping offset in days (fallback if config value is missing)
		var datepickerConfig = {
			dateFormat: "dd.mm.yy",
			minDate: shippingOffset ? shippingOffset : 0,
			beforeShowDay: $.datepicker.noWeekends
		};

		// default locale 'de' for all datepickers
		var language = $ref.data('language');
		$.datepicker.setDefaults( $.datepicker.regional[language]);
		// init datepicker
		$ref.datepicker(datepickerConfig);

		//show remove btn when date is already defined
		if($ref.val()) {
			$ref.parent().find('.js-checkout-datepicker-remove-btn').removeClass('hidden');
		}
	},

	storeSessionStorageItem: function(key, value) {
		if(sessionStorage !== undefined) {
			sessionStorage.setItem(key, value);
		}
	},

	getSessionStorageItem: function(key) {
		if(sessionStorage !== undefined) {
			return sessionStorage.getItem(key);
		}
		return null;
	},

	bindPopupMinimumOrderValue: function() {
		var self = this;
		var $popupMinOrderValue = $('.js-popup-minimum-order-value');
		var title = $popupMinOrderValue.data('title');
		var content = $popupMinOrderValue.data('content');
		var checkoutCartRefSessionKey = "checkoutCartRef";
		var cartCode = $popupMinOrderValue.data('cartCode');

		if($popupMinOrderValue.length) {
			var checkoutCartRefSessionValue = self.getSessionStorageItem(checkoutCartRefSessionKey);

			if(checkoutCartRefSessionValue === null || checkoutCartRefSessionValue !== cartCode) {
				self.storeSessionStorageItem(checkoutCartRefSessionKey, cartCode);

				ACC.colorbox.open(title, {
					html: content,
					width: "400px",
					fixed: true
				});
			}
		}
	},

	bindToggleSenderReferenceNumber: function (config) {
		var selectors = {
			toggle: '.js-toggle-senderReferenceNumber',
			section: '.js-senderReferenceNumber-form-section'
		};
		$.extend(true, selectors, config);

		$(document).on('change.toggleSenderReferenceNumber', selectors.toggle, function (event) {
			var senderReferenceNumberCheckboxState = $(event.currentTarget).is(':checked');
			var $senderReferenceNumberFormSection = $(selectors.section);

			// show/hide senderReferenceNumber section
			$senderReferenceNumberFormSection.toggleClass('hidden', !senderReferenceNumberCheckboxState);
		});

		// set the correct initial state
		$(selectors.toggle).trigger('change');
	}
};

ACC.forms = {
	_autoload: [
		"bindNumberInput",
		"bindSubmitButton",
		"bindDoubleSubmitBlocker"
	],

	bindSubmitButton: function () {
		$(document).on('click.submit', 'a[data-submit-form-id], button[data-submit-form-id]', function (e) {
			var formId = $(e.currentTarget).data('submitFormId');
			var $form = $("#" + $.escapeSelector(formId));
			if ($form.length) {
				e.preventDefault();
				$form.submit();
			}
		});
	},

	unbindSubmitButton: function () {
		$(document).off('.submit');
	},

	bindNumberInput: function () {
		var selector = {
			errorClassContainer: '.form-group',
			errorMessageElement: '[data-toggle="popover-validation-error"]'
		};

		/**
		 * helper to get precision from a numerical string
		 * @param num
		 * @returns {number}
		 */
		function decimalPlaces(num) {
			var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
			if (!match) {
				return 0;
			}
			return Math.max(
				0,
				// Number of digits right of decimal point.
				(match[1] ? match[1].length : 0)
				// Adjust for scientific notation.
				- (match[2] ? +match[2] : 0));
		}

		/**
		 * helper to calculate modulo for fractional divisors
		 * @param dividend
		 * @param fDivisor
		 * @returns {number}
		 */
		function modulo(dividend, fDivisor) {
			// convert fDivisor to integer for modulo calculation (_fDivisor has to be an integer in JS)
			var divisorPrecision = decimalPlaces(fDivisor) || 0,
				decimalShift = Math.pow(10, divisorPrecision),
				_fDivisor = Number(fDivisor) * decimalShift,
				_dividend = Number(dividend) * decimalShift;

			return (_dividend % _fDivisor) / decimalShift;
		}

		/**
		 * handler to check valid keys and deny invalid keypress
		 * @param event
		 * @returns {boolean}
		 */
		function validKeyHandler(event) {
			var keyCode = event.which || 0;
			// allow numbers, decimal, backspace, tab, delete, home, end, arrows, function keys, period and keypad numbers ONLY
			return event.altKey || event.ctrlKey || event.metaKey ||
				!(event.shiftKey) && (
					48 <= keyCode && keyCode <= 57 || // 0-9
					keyCode === 190 // . (decimal)
				) ||
				keyCode === 8 || // backspace
				keyCode === 9 || // tab
				keyCode === 46 || // delete
				(35 <= keyCode && keyCode <= 40) || // home, end, arrows
				(112 <= keyCode && keyCode <= 123) || // function keys
				keyCode === 110 || // period (keypad)
				(96 <= keyCode && keyCode <= 105); // 0-9 (keypad)
		}

		/**
		 * handler to in-/decrease value by arrow up/down
		 * (takes precision from step attribute or set precision=2, if has class js-price)
		 * @param event
		 * @returns {boolean}
		 */
		function arrowStepHandler(event) {
			var keyCode = event.which || 0;

			if (keyCode !== 38 && keyCode !== 40) return true;
			var $input = $(event.currentTarget),
				currentVal = $input.val();

			if (isNaN(Number(currentVal))) return true;
			var min = Number($input.attr('min')),
				max = Number($input.attr('max')),
				step = Number($input.attr('step')) || 1;

			if (!isNaN(min) && !currentVal.length)
				$input.val(min);
			else {
				// if step had to be adjusted => don't step up or down
				if (!adjustStep(event)) {
					// UP arrow
					if (keyCode === 38)
						$input.val(Number(currentVal) + step);
					// DOWN arrow
					else if (keyCode === 40)
						$input.val(Number(currentVal) - step);
				}
			}

			fixMinMax(event);

			if ($input.hasClass('js-price'))
				pricePrecisionHandler(event);
			else
				precisionHandler(event);
			return false;
		}

		/**
		 * handler to fix small typos during input in Number
		 * @param event
		 * @returns {boolean}
		 */
		function fixTypoValueHandler(event) {
			var $input = $(event.currentTarget),
				currentVal = $input.val();

			if (!currentVal.length) return true;
			// convert , to . and remove duplicates
			$input.val(currentVal
				.replace(/,+/g, '.')
				.replace('.', '###').replace(/\.+/g, '').replace('###', '.'));
			return true;
		}

		/**
		 * handler to correct input values
		 * @param event
		 * @returns {boolean}
		 */
		function correctionHandler(event) {
			adjustStep(event);
			fixMinMax(event);
			return true;
		}

		/**
		 * adjust the value in range of min/max
		 * @param event
		 */
		function fixMinMax(event) {
			var $input = $(event.currentTarget),
				currentVal = $input.val(),
				min = Number($input.attr('min')),
				max = Number($input.attr('max'));

			if (currentVal.length && !isNaN(currentVal)) {
				if (!isNaN(min) && Number(currentVal) < min)
					$input.val(min);
				else if (!isNaN(max) && Number(currentVal) > max)
					$input.val(max);
			}
		}

		/**
		 * adjust the value in case of an invalid step
		 * @param event
		 * @returns {boolean}
		 */
		function adjustStep(event) {
			var $input = $(event.currentTarget),
				currentVal = $input.val(),
				step = $input.attr('step');

			if (isNaN(Number(currentVal)) || isNaN(Number(step))) return false;
			var valuePrecision = decimalPlaces(currentVal),
				correctedValue = (Math.round(Number(currentVal) / Number(step)) * Number(step)).toFixed(valuePrecision);

			if (currentVal !== correctedValue) {
				$input.val(correctedValue);
				return true;
			}

			return false;
		}

		/**
		 * handler to set decimals for prices
		 * @param event
		 * @returns {boolean}
		 */
		function pricePrecisionHandler(event) {
			var $input = $(event.currentTarget),
				currentVal = $input.val();

			if (isNaN(Number(currentVal)) || !currentVal.length) return true;
			$input.val(Number(currentVal).toFixed(2));
			return true;
		}

		/**
		 * handler to set decimals for numbers
		 * @param event
		 * @returns {boolean}
		 */
		function precisionHandler(event) {
			var $input = $(event.currentTarget),
				currentVal = $input.val();

			if (isNaN(Number(currentVal)) || !currentVal.length) return true;
			var min = decimalPlaces($input.attr('min')) || 0,
				max = decimalPlaces($input.attr('max')) || 0,
				step = decimalPlaces($input.attr('step')) || 0,
				precision;

			if (step > 0)
				precision = step;
			else
				precision = Math.max(min, max);

			$input.val(Number(currentVal).toFixed(precision));
			return true;
		}

		/**
		 * handler to raise validation error
		 * @param event
		 * @returns {boolean}
		 */
		function validationHandler(event) {
			var $input = $(event.currentTarget),
				$message = $input.parents(selector.errorMessageElement),
				currentVal = $input.val(),
				min = $input.attr('min'),
				max = $input.attr('max'),
				step = $input.attr('step');

			if (!currentVal.length || !$message.length) return true;
			// TODO: remove popover-content from DOM in errorMessageElement, read from DOM elsewhere and set here as {content:'text'}, to switch messages
			$message.popover();
			var popover = $message.data('bs.popover');

			if (!popover instanceof $.fn.popover.Constructor) return true;
			// handle wrong Number input
			if (isNaN(Number(currentVal))) {
				$input.closest(selector.errorClassContainer).addClass('has-error');
				if (!popover.tip().is(':visible'))
					$message.popover('show');
			}
			// handle minimum range error
			else if (min && min.length && !isNaN(Number(min)) && Number(currentVal) < Number(min)) {
				$input.closest(selector.errorClassContainer).addClass('has-error');
				if (!popover.tip().is(':visible'))
					$message.popover('show');
			}
			// handle maximum range error
			else if (max && max.length && !isNaN(Number(max)) && Number(currentVal) > Number(max)) {
				$input.closest(selector.errorClassContainer).addClass('has-error');
				if (!popover.tip().is(':visible'))
					$message.popover('show');
			}
			// handle step modulo error
			else if (step && step.length && !isNaN(Number(step))) {
				var modulo = Number(step),
					shift = 0,
					valuePrec = decimalPlaces(currentVal),
					moduloPrec = decimalPlaces(step),
					shiftPrec, maxPrec;

				if (min && min.length && !isNaN(Number(min))) {
					shift = Number(min);
					shiftPrec = decimalPlaces(min);
				}
				maxPrec = Math.max(0, valuePrec, moduloPrec, shiftPrec);
				// precision fix for modulo operation: multiply all operands with max precision
				var pow = Math.pow(10, maxPrec),
					_currentVal = Math.round(Number(currentVal) * pow),
					_modulo = Math.round(modulo * pow),
					_shift = Math.round(shift * pow);

				if (Number((_currentVal - _shift) % _modulo) !== 0) {
					$input.closest(selector.errorClassContainer).addClass('has-error');
					if (!popover.tip().is(':visible'))
						$message.popover('show');
				}
			}
			// send custom event to $input with validation result (no errorMessage was placed) as extra parameter
			$input.trigger('numberInputValidation', [!popover.tip().is(':visible')]);
			return true;
		}

		/**
		 * handler to reset validation error (see validationHandler)
		 * @param event
		 * @returns {boolean}
		 */
		function focusHandler(event) {
			var $input = $(event.currentTarget),
				$message = $input.parents(selector.errorMessageElement),
				popover = $message.data('bs.popover');

			$input.closest(selector.errorClassContainer).removeClass('has-error');
			if (popover instanceof $.fn.popover.Constructor) {
				$message.popover('hide');
			}
			return true;
		}

		$(document).on('focusin.numberInput', 'input.js-number, input.js-price', focusHandler);
		$(document).on('keydown.numberInput', 'input.js-number, input.js-price', validKeyHandler);
		$(document).on('keydown.numberInput', 'input.js-number, input.js-price', arrowStepHandler);
		$(document).on('keyup.numberInput input.numberInput change.numberInput', 'input.js-number, input.js-price', fixTypoValueHandler);
		// "change" event is not fired in MS Edge/EdgeHTML, so additional listening to "focusout" event is necessary
		// see https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/15181565/
		$(document).on('change.numberInput focusout.numberInput', 'input.js-number, input.js-price', correctionHandler);
		$(document).on('change.numberInput focusout.numberInput', 'input.js-number', precisionHandler);
		$(document).on('change.numberInput focusout.numberInput', 'input.js-price', pricePrecisionHandler);
		$(document).on('change.numberInput focusout.numberInput', 'input.js-number, input.js-price', validationHandler);
	},

	unbindNumberInput: function () {
		$(document).off('.numberInput');
	},

	/**
	 * attaches a submitHandler to form tags with class js-block-submit
	 * - complete UI is blocked
	 * - for ajaxForms implement $.blockUI() and $.unblockUI() inside ajax-callback
	 */
	bindDoubleSubmitBlocker: function () {
		var blockUIOptions = {
			message: null,
			overlayCSS: {
				backgroundColor: '#000',
				opacity: 0.05
			},
			baseZ: 5000
		};

		function submitHandler(event) {
			if (!event.isDefaultPrevented()) {
				$.blockUI(blockUIOptions);
			}
		}

		$(document).on("submit.submitBlocker", "form.js-block-submit", submitHandler);
	},

	unbindDoubleSubmitBlocker: function () {
		$(document).off('.submitBlocker');
	}
};

ACC.refinements = {

	_autoload: [
		"bindMoreLessToggles",
		"bindFacetCheckbox"
	],

	bindFacetCheckbox: function () {
		$(document).on("change",".js-facet-checkbox",function(){
			$(this).parents("form").submit();
		})
	},

	bindMoreLessToggles: function (){
		$(document).on("click",".js-more-facet-values-link",function(e){
			e.preventDefault();
			$(this).parents(".js-facet").find(".js-facet-top-values").hide();
			$(this).parents(".js-facet").find(".js-facet-list-hidden").show();

			$(this).parents(".js-facet").find(".js-more-facet-values").hide();
			$(this).parents(".js-facet").find(".js-less-facet-values").show();
		});

		$(document).on("click",".js-less-facet-values-link",function(e){
			e.preventDefault();
			$(this).parents(".js-facet").find(".js-facet-top-values").show();
			$(this).parents(".js-facet").find(".js-facet-list-hidden").hide();

			$(this).parents(".js-facet").find(".js-more-facet-values").show();
			$(this).parents(".js-facet").find(".js-less-facet-values").hide();
		})
	}
};

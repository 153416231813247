ACC.lettershop = {
	_autoload: [
		"bindRadioButtons",
		"bindUploadButtons",
		"initPopover",
		"restoreCoverletterGreetingTexts",
		"setTemplateFromDOM"
	],

	properties: {
		currentUploadBtnClone: {},
		fileItemTemplate: {}
	},

	selectors: {
		downloadLink: ".lettershop-fileItem__downloadLink",
		editFileButton: ".js-lettershop-fileItem__editFileButton",
		errorMsgFilename: '.js-lettershop-uploadform-error-message-filename',
		errorMsgLogoFileFormat: '.js-lettershop-uploadform-error-message-logo-formats',
		errorMsgLogoDimensions: '.js-lettershop-uploadform-error-message-logo-dimensions',
		fileInputSingle: ".js-lettershop-uploadButton-single",
		fileInputMulti: ".js-lettershop-uploadButton-multi",
		fileItem: ".lettershop-fileItem",
		filename: ".lettershop-fileItem__filename",
		greetingTextDE: ".lettershop-uploadform-uploadCoverLetters__greetingText-de",
		greetingTextFR: ".lettershop-uploadform-uploadCoverLetters__greetingText-fr",
		greetingTextIT: ".lettershop-uploadform-uploadCoverLetters__greetingText-it",
		lettershopFormEntry: ".lettershop-uploadform-entry",
		popover: '.js-lettershop-uploadform-popover',
		removeFileButton: ".js-lettershop-fileItem__removeFileButton",
		uploadLogoWrapper: '.js-lettershop-uploadform-uploadLogo-wrapper',
		virtualUploadButton: ".js-lettershop-fileItem__uploadButton-virtual",
		wrapperSingle: ".js-lettershop-uploadform-wrapper-single",
		wrapperMulti: ".js-lettershop-uploadform-wrapper-multi"
	},

	bindRadioButtons: function () {
		$(document).on("change.lettershopSupplements", ".js-supplements-radio", function (e) {
			var value = $(e.currentTarget).val();
			$('.js-lettershop-supplements-deliveryInfo').toggleClass('hidden', value !== 'true');
		});

		// trigger the correct onchange event
		$('.js-supplements-radio[type=radio]:checked').trigger('change');
	},

	bindUploadButtons: function () {
		var self = this;

		// is called when the virtual upload buttons (addresslist/logo/coverletter) and edit buttons (addresslist/logo) are clicked
		$(document).on("click.lettershopUploadform", self.selectors.virtualUploadButton + ', ' + self.selectors.editFileButton, function (e) {
			e.preventDefault();
			var $ref = $(e.currentTarget);
			var $uploadBtn = $ref.parent().find('input[type=file]');
			$(":input[id^='lettershop-uploadButton-logo-']").attr('accept', '.gif, .jpg, .jpeg, .png');
			// clone current upload button in order to restore it when the filename validation fails
			self.properties.currentUploadBtnClone = $uploadBtn.clone(true);
			$uploadBtn.click();
		});

		// is called when a new addresslist/logo is selected
		$(document).on('change.lettershopUploadform', self.selectors.fileInputSingle, function (e) {
			var filename = e.target.files[0].name;
			var uploadedFile = e.currentTarget.files[0];
			var $fileItem = $(e.target).closest(self.selectors.fileItem);
			var $wrapperSingle = $fileItem.closest(self.selectors.wrapperSingle);

			// show alert box, if filenames are not unique
			if (!ACC.lettershop.checkIfFilenameIsUnique($fileItem, filename)) {
				return false;
			}

			// hide previous alert messages
			ACC.lettershop.toggleAlertBox($wrapperSingle, self.selectors.errorMsgLogoFileFormat, true);
			ACC.lettershop.toggleAlertBox($wrapperSingle, self.selectors.errorMsgLogoDimensions, true);

			// validate logo file format and dimensions
			var logoMinWidth = Number($wrapperSingle.data('logoMinWidth')),
				logoMinHeight = Number($wrapperSingle.data('logoMinHeight')),
				logoFileFormats = $wrapperSingle.data('logoFileFormats');
			if (logoMinWidth > 0 && logoMinHeight > 0 && logoFileFormats !== '') {
				// validate file format
				self.validateFileFormat($fileItem, uploadedFile, logoFileFormats);

				// validate logo dimensions
				self.validateLogoDimensions($fileItem, uploadedFile, logoMinWidth, logoMinHeight);
			}

			// update/set filename, show edit+remove button and hide upload button / download link
			$fileItem.find(self.selectors.virtualUploadButton).addClass('hidden');
			$fileItem.find(self.selectors.downloadLink).addClass('hidden');
			$fileItem.find(self.selectors.editFileButton).removeClass('hidden');
			$fileItem.find(self.selectors.filename).text(filename);
		});

		// is called when a new coverletter is selected
		$(document).on('change.lettershopUploadform', self.selectors.fileInputMulti, function (e) {
			var filename = e.target.files[0].name;
			var $fileInputCoverletter = $(e.target);
			var $fileItem = $fileInputCoverletter.closest(self.selectors.fileItem);
			var $wrapperMulti = $fileInputCoverletter.closest(self.selectors.wrapperMulti);

			// show alert box, if filenames are not unique
			if (!ACC.lettershop.checkIfFilenameIsUnique($fileItem, filename)) {
				return false;
			}

			// set dynamic id and name for upload button
			$wrapperMulti.append(self.properties.fileItemTemplate.clone());
			ACC.lettershop.setDynamicAttributes($wrapperMulti);

			// update/set filename, show remove button and remove virtual upload button
			$fileItem.find(self.selectors.removeFileButton).removeClass('hidden');
			$fileItem.find(self.selectors.virtualUploadButton).remove();
			$fileItem.find(self.selectors.filename).text(filename);
		});

		// remove event for coverletters/addresslist
		$(document).on("click.lettershopUploadform", self.selectors.removeFileButton, function (e) {
			e.preventDefault();
			var $removeFileButton = $(e.currentTarget),
				$fileItem = $removeFileButton.parent();
			var currentFilePk = $removeFileButton.data('filePk');
			var isSingleFileItem = $removeFileButton.data('isSingleFileItem');
			var $filesPkHolder = $fileItem.closest('.lettershop-uploadform-entry').find('.deleted-files-pk-holder');
			var deletedFilesPKs = $filesPkHolder.val();

			// if the selected file was already uploaded, add its pk to the pk holder
			if (currentFilePk !== '') {
				deletedFilesPKs += currentFilePk + ',';
				$filesPkHolder.val(deletedFilesPKs);
			}

			// if remove button is unique in the section (addresslist or logo) => show initial upload button
			if (isSingleFileItem) {
				var $wrapperSingle = $fileItem.closest(self.selectors.wrapperSingle);
				var dataButtonId = $wrapperSingle.data('buttonId');
				var dataButtonName = $wrapperSingle.data('buttonName');

				// remove the whole item
				$fileItem.remove();

				var $fileItemClone = $wrapperSingle.find(self.selectors.fileItem).clone().removeClass('hidden');
				$fileItemClone.find(self.selectors.fileInputSingle).first().attr('id', dataButtonId).attr('name', dataButtonName);
				$wrapperSingle.prepend($fileItemClone);
			}
			else {
				// remove the whole item
				$fileItem.remove();
			}
		});
	},

	/**
	 * iterate over fileInputs of given giftcard-coverletter-upload-form
	 * and set uniq ID and correct name
	 * @param $uploadCoverLettersWrapper
	 */
	setDynamicAttributes: function ($uploadCoverLettersWrapper) {
		var entryNumber = $uploadCoverLettersWrapper.data('entryNumber');
		var entryUploadbuttonId = $uploadCoverLettersWrapper.data('entryUploadbuttonId');
		var entryUploadbuttonName = $uploadCoverLettersWrapper.data('entryUploadbuttonName');
		var uploadButtonCounter = 1;

		$uploadCoverLettersWrapper.find(this.selectors.fileInputMulti).each(function (index, fileInput) {
			var $fileInput = $(fileInput);

			$fileInput.attr('name', entryUploadbuttonName);
			$fileInput.attr('id', entryUploadbuttonId + '-' + entryNumber + '-' + uploadButtonCounter);

			uploadButtonCounter++;
		});
	},

	/**
	 * clone empty lettershop fileItem
	 */
	setTemplateFromDOM: function () {
		ACC.lettershop.properties.fileItemTemplate = $(this.selectors.fileItem).last().clone();
	},

	unbindLettershopSupplementsEvents: function () {
		$(document).off('.lettershopSupplements');
	},

	unbindLettershopUploadformEvents: function () {
		$(document).off('.lettershopUploadform');
	},

	/**
	 * checks if the filename of the selected file exists already in the entry. The functions goes through the entry and counts the occurrencies in addresslist / cover letters.
	 * @param $fileItem
	 * @param filename
	 * @returns {boolean} true: if filename doesn't exists, otherwise false
	 */
	checkIfFilenameIsUnique: function ($fileItem, filename) {
		var self = this;
		var counter = 0;
		var $lettershopFormEntry = $fileItem.closest(self.selectors.lettershopFormEntry);

		ACC.lettershop.toggleAlertBox($lettershopFormEntry, self.selectors.errorMsgFilename, true);

		$lettershopFormEntry.find(this.selectors.fileItem).each(function (index, element) {
			if ($.trim($(element).find('.lettershop-fileItem__filename').text()) === filename) {
				counter++;
			}
		});

		if (counter > 0) {
			ACC.lettershop.toggleAlertBox($lettershopFormEntry, self.selectors.errorMsgFilename, false);
			$fileItem.find('input[type=file]').replaceWith(self.properties.currentUploadBtnClone);

			return false;
		}

		return true;
	},

	toggleAlertBox: function ($wrapper, selector, state) {
		var $alertBox = $wrapper.find(selector);
		$alertBox.toggleClass('hidden', state);
	},

	restoreCoverletterGreetingTexts: function () {
		var self = this;

		// restore greeting texts for digital giftcards
		$(self.selectors.wrapperMulti).each(function () {
			$(this).find(self.selectors.greetingTextDE).val($(this).data('greetingTextDe'));
			$(this).find(self.selectors.greetingTextFR).val($(this).data('greetingTextFr'));
			$(this).find(self.selectors.greetingTextIT).val($(this).data('greetingTextIt'));
		});
	},

	initPopover: function () {
		$(this.selectors.popover).popover();
	},

	validateLogoDimensions: function ($fileItem, uploadedFile, logoMinWidth, logoMinHeight) {
		var self = this;
		var fileReader = new FileReader();
		var $lettershopFormEntry = $fileItem.closest(self.selectors.lettershopFormEntry);

		fileReader.onloadend = function (e) {
			var image = new Image();

			image.src = e.target.result;
		};

		fileReader.readAsDataURL(uploadedFile);
	},

	validateFileFormat: function ($fileItem, uploadedFile, regexPattern) {
		var self = this;
		var $lettershopFormEntry = $fileItem.closest(self.selectors.lettershopFormEntry);

		// check file format with regex
		var regex = new RegExp(regexPattern);
		var array = regex.exec(uploadedFile.type);

		if (array === null) {
			// trigger remove event in order to prevent sending invalid files
			$fileItem.find(self.selectors.removeFileButton).click();

			ACC.lettershop.toggleAlertBox($lettershopFormEntry, self.selectors.errorMsgLogoFileFormat, false);
		}
	}
};

ACC.checkoutPersonalization = {
	_autoload: [
		"bindRadiobuttons"
	],

	bindRadiobuttons: function () {
		$(document).on("change", ".js-coverletter-radio", function (e) {
			var value = $(e.currentTarget).val();
			$('.lettershop-uploadform').toggleClass('hidden', value !== 'true');
		});

		// trigger the correct onchange event
		$('.js-coverletter-radio[type=radio]:checked').trigger('change');
	}
};
ACC.global = {

    _autoload: [
        ["passwordStrength", $('.password-strength').length > 0],
        "bindToggleOffcanvas",
        "bindToggleXsSearch",
        "bindHoverIntentMainNavigation",
        "initImager",
        "backToHome",
        "backToTop"
    ],

    /**
     * StorageHelperModel - wrapping common tasks with browsers storages
     * @param storageType - "sessionStorage" | "localStorage"
     * @returns {{getItem: getItem, check: (function(): boolean), setItem: setItem}} - StorageHelperInstance
     * @constructor
     */
    StorageHelperModel: function (storageType) {
        return {
            check: function () {
                return typeof (window[storageType]) !== "undefined";
            },
            getItem: function (key) {
                if (this.check()) {
                    return window[storageType].getItem(key);
                }
                return null;
            },
            setItem: function (key, value) {
                if (this.check()) {
                    // use try-catch to avoid saving exceptions due to full memory
                    // storage memory is set to 0 in private mode on mobile safari (> iOS 5)
                    try {
                        window[storageType].setItem(key, value);
                    } catch (e) {
                        console.error(e);
                    }
                }
            }
        }
    },

    /**
     * hide tooltip on elements
     * @param $elements
     */
    hideErrorMsgTooltip: function ($elements) {
        $elements.filter('[data-toggle="popover"]').popover("hide");
    },

    /**
     * show timed tooltip message on elements
     * @param $elements
     * @param errorMsg
     */
    showErrorMsgTooltip: function ($elements, errorMsg) {
        var self = this;
        var timeout = 4000;
        $elements.filter('[data-toggle="popover"]').each(function (index, element) {
            var $element = $(element);
            clearTimeout($element.data('timerId'));

            if ($element.data('bs.popover') === undefined) {
                $element.popover({
                    trigger: 'manual',
                    content: errorMsg
                });
            }
            else {
                $element.data('bs.popover').options.content = errorMsg;
                $element.data('bs.popover').setContent();
            }

            $element.popover('show');

            var timerId = setTimeout(function () {
                self.hideErrorMsgTooltip($element);
            }, timeout);

            $element.data('timerId', timerId);
        });
    },

    passwordStrength: function ($elements) {
        $elements = ($elements && $elements.length) ? $elements : $('.password-strength');
        $elements.pstrength({
            verdicts: [ACC.pwdStrengthTooShortPwd,
                ACC.pwdStrengthVeryWeak,
                ACC.pwdStrengthWeak,
                ACC.pwdStrengthMedium,
                ACC.pwdStrengthStrong,
                ACC.pwdStrengthVeryStrong],
            minCharText: ACC.pwdStrengthMinCharText
        });
    },

    bindToggleOffcanvas: function () {
        $(document).on("click", ".js-toggle-sm-navigation", function () {
            ACC.global.toggleClassState($("main"), "offcanvas");
            ACC.global.toggleClassState($("html"), "offcanvas");
            ACC.global.toggleClassState($("body"), "offcanvas");
            ACC.global.resetXsSearch();
        });
    },

    bindToggleXsSearch: function () {
        $(document).on("click", ".js-toggle-xs-search", function () {
            ACC.global.toggleClassState($(".site-search"), "active");
            ACC.global.toggleClassState($(".js-mainHeader .navigation--middle"), "search-open");
        });
    },

    resetXsSearch: function () {
        $('.site-search').removeClass('active');
        $(".js-mainHeader .navigation--middle").removeClass("search-open");
    },

    toggleClassState: function ($e, c) {
        $e.hasClass(c) ? $e.removeClass(c) : $e.addClass(c);
        return $e.hasClass(c);
    },

    bindHoverIntentMainNavigation: function () {

        enquire.register("screen and (min-width:" + screenMdMin + ")", {

            match: function () {
                // on screens larger or equal screenMdMin (1024px) calculate position for .sub-navigation
                $(".js-enquire-has-sub").hoverIntent(function () {
                    var $this = $(this),
                        itemWidth = $this.width();
                    var $subNav = $this.find('.js_sub__navigation'),
                        subNavWidth = $subNav.outerWidth();
                    var $mainNav = $('.js_navigation--bottom'),
                        mainNavWidth = $mainNav.width();

                    // get the left position for sub-navigation to be centered under each <li>
                    var leftPos = $this.position().left + itemWidth / 2 - subNavWidth / 2;
                    // get the top position for sub-navigation. this is usually the height of the <li> unless there is more than one row of <li>
                    var topPos = $this.position().top + $this.height();

                    if (leftPos > 0 && leftPos + subNavWidth < mainNavWidth) {
                        // .sub-navigation is within bounds of the .main-navigation
                        $subNav.css({
                            "left": leftPos,
                            "top": topPos,
                            "right": "auto"
                        });
                    } else if (leftPos < 0) {
                        // .suv-navigation can't be centered under the <li> because it would exceed the .main-navigation on the left side
                        $subNav.css({
                            "left": 0,
                            "top": topPos,
                            "right": "auto"
                        });
                    } else if (leftPos + subNavWidth > mainNavWidth) {
                        // .suv-navigation can't be centered under the <li> because it would exceed the .main-navigation on the right side
                        $subNav.css({
                            "right": 0,
                            "top": topPos,
                            "left": "auto"
                        });
                    }
                    $this.addClass("show-sub");
                }, function () {
                    $(this).removeClass("show-sub")
                });
            },

            unmatch: function () {
                // on screens smaller than screenMdMin (1024px) remove inline styles from .sub-navigation and remove hoverIntent
                $(".js_sub__navigation").removeAttr("style");
                $(".js-enquire-has-sub").hoverIntent(function () {
                    // unbinding hover
                });
            }

        });
    },

    initImager: function (elems) {
        elems = elems || '.js-responsive-image';
        this.imgr = new Imager(elems);
    },

    reprocessImages: function (elems) {
        elems = elems || '.js-responsive-image';
        if (this.imgr == undefined) {
            this.initImager(elems);
        } else {
            this.imgr.checkImagesNeedReplacing($(elems));
        }
    },

    // usage: ACC.global.addGoogleMapsApi("callback function"); // callback function name like "ACC.global.myfunction"
    addGoogleMapsApi: function (callback) {
        if (callback != undefined && $(".js-googleMapsApi").length == 0) {
            $('head').append('<script class="js-googleMapsApi" type="text/javascript" src="//maps.googleapis.com/maps/api/js?key=' + ACC.config.googleApiKey + '&sensor=false&callback=' + callback + '"></script>');
        } else if (callback != undefined) {
            eval(callback + "()");
        }
    },

    backToHome: function () {
        $(".backToHome").on("click", function () {
            var sUrl = ACC.config.contextPath;
            window.location = sUrl;
        });
    },

    backToTop:function scrollToTop() {

        var currentPosition = $(window).scrollTop();

        $(window).scroll(function () {

            var lastScrollPosition = $(window).scrollTop();

            if (currentPosition > lastScrollPosition) {
                $('[data-scroll-to-top-button="true"]').show();
            } else if (currentPosition < lastScrollPosition) {
                $('[data-scroll-to-top-button="true"]').hide();
            }
            currentPosition = lastScrollPosition;

            if (lastScrollPosition === 0) {
                $('[data-scroll-to-top-button="true"]').hide();
            }
        });

        $('[data-scroll-to-top-button="true"]').on('click', function (event) {
            $('body,html').animate({
                scrollTop: 0
            }, 777);
        });
    }
};
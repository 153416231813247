ACC.forgottenpassword = {

	_autoload: [
		"bindLink"
	],

	selectors: {
		link: ".js-password-forgotten",
		form: "form#b2bgkForgottenPwdForm",
		messageContainer: "#validUserName",
		container: ".forgotten-password"
	},

	bindLink: function () {
		var self = this;
		$(document).on("click.forgottenpassword", self.selectors.link, function (event) {
			event.preventDefault();
			var $link = $(event.currentTarget);

			var cbox = ACC.colorbox.open(
				$link.data("cboxTitle"),
				{
					href: $link.data("link"),
					width: "350px",
					onComplete: function () {
						$(self.selectors.form).ajaxForm({
							success: function (data) {
								if ($(data).closest(self.selectors.messageContainer).length) {
									$(self.selectors.container).replaceWith(data);
								}
								else {
									$(self.selectors.form).find(".control-group").replaceWith($(data).find(".control-group"));
								}
								ACC.colorbox.config.onComplete();
							}
						});
					}
				}
			);
		});
	},
	unbindLink: function () {
		$(document).off('.forgottenpassword');
	}
};
ACC.colorbox = {
	_autoload: [
		"bindScrollHandler",
		"bindResizeHandler",
		"bindBackdropFixing"
	],

	config: {
		maxWidth: "100%",
		opacity: 0.7,
		width: "auto",
		transition: "none",
		close: '<span class="glyphicon glyphicon-remove"></span>',
		title: '<div class="headline"><span class="headline-text">{title}</span></div>',
		onComplete: function () {
			ACC.colorbox.resize();
			// compensate lazy loaded content with static delayed resizing
			setTimeout(ACC.colorbox.resize, 1000);
			ACC.common.refreshScreenReaderBuffer();
		},
		onClosed: function () {
			ACC.common.refreshScreenReaderBuffer();
		}
	},

	/**
	 * responsive dimensions setup
	 * - it is applied, if colorbox is initialized with {className:"colorbox-responsive"}
	 */
	responsiveDimensions: {
		width: '95%',
		height: '95%',
		maxWidth: '960px',
		maxHeight: '960px',
	},

	open: function (title, options) {
		var config = $.extend({}, ACC.colorbox.config, options);
		// apply responsive setup
		if (config.className && $.inArray("colorbox-responsive", config.className.split(/\s+/g)) > -1) {
			$.extend(config, ACC.colorbox.responsiveDimensions);
		}
		config.title = config.title.replace(/{title}/g, title);
		return $.colorbox(config);
	},

	resize: function () {
		var $colorbox = $('#colorbox:visible');
		if (!$colorbox.length) return; // early exit, if colorbox is not visible
		if ($colorbox.hasClass("colorbox-responsive")) {
			// resize hard with special options, if colorbox was initialized with {className:"colorbox-responsive"}
			var cboxOptions = ACC.colorbox.responsiveDimensions;
			$.colorbox.resize({
				width: window.innerWidth > parseInt(cboxOptions.maxWidth) ? cboxOptions.maxWidth : cboxOptions.width,
				height: window.innerHeight > parseInt(cboxOptions.maxHeight) ? cboxOptions.maxHeight : cboxOptions.height
			});
		}
		else {
			$.colorbox.resize();
		}
		ACC.colorbox.fixBackdropHeight();
	},

	close: function () {
		$.colorbox.close();
	},

	/**
	 * fix height of backdrop for colorboxes, that are larger than the body
	 */
	fixBackdropHeight: function () {
		var $colorbox = $("#colorbox:visible");
		if (!$colorbox.length) return; // early exit, if colorbox is not visible
		var $backdrop = $("#cboxOverlay"),
			colorboxDimensionY = $colorbox.offset().top + $colorbox.outerHeight(true);
		if (colorboxDimensionY > $('body').height()) {
			$backdrop.height(colorboxDimensionY);
		}
		else {
			$backdrop.css('height', '');
		}
	},

	bindBackdropFixing: function () {
		$(document).on("scroll.colorboxBackdropFix", ACC.colorbox.fixBackdropHeight);
		$(document).on("cbox_complete.colorboxBackdropFix", ACC.colorbox.fixBackdropHeight);
	},

	unbindBackdropFixing: function () {
		$(document).off(".colorboxBackdropFix");
	},

	/**
	 * reposition colorbox on document scrolling
	 * - since {reposition: true} only has effect on window.resize, NOT on document.scroll
	 * - advantage to {fixed:true} is, that larger content (colorbox is higher than screen) is reachable
	 * - triggers custom event to hook in
	 * @param event
	 */
	documentScrollRepositionHandler: function (event) {
		var centerTolerance = 0;
		var $colorbox = $('#colorbox:visible');
		if (!$colorbox.length) return; // early exit, if colorbox is not visible
		var documentScrollTop = Math.max(0, $(document).scrollTop()),
			windowHeight = $(window).height(),
			colorboxTop = Math.max(0, $colorbox.offset().top),
			colorboxHeight = $colorbox.height();
		var colorboxIsHigherThanScreen = colorboxHeight > windowHeight;
		var colorboxTopIsBelowScreenTop = colorboxTop > documentScrollTop;
		var colorboxNotCentered = Math.abs(colorboxTop + colorboxHeight/2 - (documentScrollTop + windowHeight/2)) > centerTolerance;
		if (colorboxIsHigherThanScreen && colorboxTopIsBelowScreenTop || !colorboxIsHigherThanScreen && colorboxNotCentered) {
			$.colorbox.position();
			$colorbox.trigger("colorbox_reposition_onscroll", {scrollTop: documentScrollTop});
		}
		ACC.colorbox.fixBackdropHeight();
	},

	bindScrollHandler: function () {
		$(document).on("scroll.colorbox", this.documentScrollRepositionHandler);
	},

	unbindScrollHandler: function () {
		$(document).off("scroll.colorbox");
	},

	bindResizeHandler: function () {
		$(window).on("resize.colorbox", ACC.colorbox.resize);
	},

	unbindResizeHandler: function () {
		$(window).off("resize.colorbox");
	}
};

ACC.paginationsort = {

	_autoload: [
		"bindAll"
	],

	downUpKeysPressed: false,

	bindAll: function ()
	{
		this.bindPaginationSort();
	},

	bindPaginationSort: function ()
	{
		with (ACC.paginationsort)
		{
			bindSortForm('#sortForm1');
			bindSortForm('#sortForm2');
		}
	},

	bindSortForm: function (sortFormSelector)
	{
//  browser.msie has been removed from jQuery as of version 1.9. Modernizr is recommended as a replacement
//	issue created
//		if ($.browser.msie)
//		{
// 			this.sortFormIEFix($(sortForm).children('select'), $(sortForm).children('select').val());
//		}

		$(document).on('change', sortFormSelector, function (event)
		{
			var $form = $(event.currentTarget);
//			if (!$.browser.msie)
//			{
//				$form.submit();
//			}
//			else
//			{
				if (!ACC.paginationsort.downUpPressed)
				{
					$form.submit();
				}
				ACC.paginationsort.downUpPressed = false;
//			}
		});
	},

	sortFormIEFix: function (sortOptions, selectedOption)
	{
		sortOptions.keydown(function (e)
		{
			// Pressed up or down keys
			if (e.keyCode === 38 || e.keyCode === 40)
			{
				ACC.paginationsort.downUpPressed = true;
			}
			// Pressed enter
			else if (e.keyCode === 13 && selectedOption !== $(this).val())
			{
				$(this).closest('form').submit();
			}
			// Any other key
			else
			{
				ACC.paginationsort.downUpPressed = false;
			}
		});
	}
};

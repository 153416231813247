ACC.productDetail = {

	_autoload: [
		"initPageEvents",
		"bindVariantOptions"
	],


	checkQtySelector: function (self, mode) {
		var input = $(self).parents(".js-qty-selector").find(".js-qty-selector-input");
		var inputVal = parseInt(input.val());
		var max = input.data("max");
		var minusBtn = $(self).parents(".js-qty-selector").find(".js-qty-selector-minus");
		var plusBtn = $(self).parents(".js-qty-selector").find(".js-qty-selector-plus");

		$(self).parents(".js-qty-selector").find(".btn").removeAttr("disabled");

		if (mode == "minus") {
			if (inputVal != 1) {
				ACC.productDetail.updateQtyValue(self, inputVal - 1)
				if (inputVal - 1 == 1) {
					minusBtn.attr("disabled", "disabled")
				}

			} else {
				minusBtn.attr("disabled", "disabled")
			}
		} else if (mode == "reset") {
			ACC.productDetail.updateQtyValue(self, 1)

		} else if (mode == "plus") {
			if (max == "FORCE_IN_STOCK") {
				ACC.productDetail.updateQtyValue(self, inputVal + 1)
			} else if (inputVal <= max) {
				ACC.productDetail.updateQtyValue(self, inputVal + 1)
				if (inputVal + 1 == max) {
					plusBtn.attr("disabled", "disabled")
				}
			} else {
				plusBtn.attr("disabled", "disabled")
			}
		} else if (mode == "input") {
			if (inputVal == 1) {
				minusBtn.attr("disabled", "disabled")
			} else if (max == "FORCE_IN_STOCK" && inputVal > 0) {
				ACC.productDetail.updateQtyValue(self, inputVal)
			} else if (inputVal == max) {
				plusBtn.attr("disabled", "disabled")
			} else if (inputVal < 1) {
				ACC.productDetail.updateQtyValue(self, 1)
				minusBtn.attr("disabled", "disabled")
			} else if (inputVal > max) {
				ACC.productDetail.updateQtyValue(self, max)
				plusBtn.attr("disabled", "disabled")
			}
		} else if (mode == "focusout") {
			if (isNaN(inputVal)) {
				ACC.productDetail.updateQtyValue(self, 1);
				minusBtn.attr("disabled", "disabled");
			} else if (inputVal >= max) {
				plusBtn.attr("disabled", "disabled");
			}
		}

	},

	updateAmountValue: function (self, value) {
		var $addtocartAmount = $(self).parents(".addtocart-component").find("#addToCartForm").find("input[name=value]");
		$addtocartAmount.val(value);

		// update amount on image
		$('.js-amount-overlay').text(value);

		// update layout for amount overlay
		$('.js-amount-overlay').each(ACC.product.setAmountContainerLayoutAndStyle);
	},

	updateQtyValue: function (self, value) {
		var input = $(self).parents(".js-qty-selector").find(".js-qty-selector-input");
		var addtocartQty = $(self).parents(".addtocart-component").find("#addToCartForm").find(".js-qty-selector-input");
		var configureQty = $(self).parents(".addtocart-component").find("#configureForm").find(".js-qty-selector-input");
		input.val(value);
		addtocartQty.val(value);
		configureQty.val(value);
	},

	initPageEvents: function () {
		$(document).on("click", '.js-qty-selector .js-qty-selector-minus', function () {
			ACC.productDetail.checkQtySelector(this, "minus");
		})

		$(document).on("click", '.js-qty-selector .js-qty-selector-plus', function () {
			ACC.productDetail.checkQtySelector(this, "plus");
		})

		$(document).on("keydown", '.js-qty-selector .js-qty-selector-input', function (e) {

			if (($(this).val() != " " && ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105))) || e.which == 8 || e.which == 46 || e.which == 37 || e.which == 39 || e.which == 9) {
			}
			else if (e.which == 38) {
				ACC.productDetail.checkQtySelector(this, "plus");
			}
			else if (e.which == 40) {
				ACC.productDetail.checkQtySelector(this, "minus");
			}
			else {
				e.preventDefault();
			}
		})

		$(document).on("keyup", '.js-qty-selector .js-qty-selector-input', function (e) {
			ACC.productDetail.checkQtySelector(this, "input");
			ACC.productDetail.updateQtyValue(this, $(this).val());

		})

		$(document).on("focusout", '.js-qty-selector .js-qty-selector-input', function (e) {
			ACC.productDetail.checkQtySelector(this, "focusout");
			ACC.productDetail.updateQtyValue(this, $(this).val());
		})

		$(document).on("focusout keyup", '.js-pdp-amount-selector-input', function (e) {
			var amount = $(this).val();
			ACC.productDetail.updateAmountValue(this, amount);
		})

		$(document).on("click", ".js-set-pdp-wrapping-product-code", function (event) {
			var $this = $(event.currentTarget);
			var wrappingProductCode = $this.data("productCode");
			var wrappingImgUrl = $this.data("imgUrl");
			var $wrappingImg = $("<img>");
			$wrappingImg.attr("src", wrappingImgUrl);
			$("#wrappingProductCode").val(wrappingProductCode);
			$(".js-selected-pdp-wrapping-preview").empty().append($wrappingImg);
			$("input[name='wrapping'][value='premium']").prop("checked",true).data({productCode: wrappingProductCode, imgUrl: wrappingImgUrl});
			ACC.colorbox.close();
			return false;
		});

		$(document).on("change", ".wrapping-pdp-radio-btn", function (event) {
			var $this = $(event.currentTarget);
			var wrappingProductCode = $this.data("productCode") || "";
			var wrappingImgUrl = $this.data("imgUrl");
			var $wrappingPreviewContainer = $(".js-selected-pdp-wrapping-preview");
			$("#wrappingProductCode").val(wrappingProductCode);
			$(".js-select-pdp-wrapping-model").toggle($this.val() == 'premium');
			$wrappingPreviewContainer.empty();
			if (wrappingImgUrl) {
				var $wrappingImg = $("<img>");
				$wrappingImg.attr("src", wrappingImgUrl);
				$wrappingPreviewContainer.append($wrappingImg);
			}
		});
		// trigger change handler
		$(".wrapping-pdp-radio-btn:checked").change();

		$(document).on("click", ".js-select-pdp-wrapping-model", function (event) {
			var url = ACC.config.encodedContextPath + "/search/wrapping/list";
			var method = "GET";
			var $template = $(".js-wrapping-template").first();
			var title = $template.data('modalTitle');
			var $wrappingTemplate = $($template.html());
			$wrappingTemplate.find(".js-select-wrapping").removeClass("js-select-wrapping").addClass("js-set-pdp-wrapping-product-code");
			$.ajax({
				url: url,
				type: method,
				success: function (data) {
					var $productList = $('<div class="item__list"/>');
					$.each(data.results, function () {
						var $product = $wrappingTemplate.clone(),
							imgUrl = $product.find(".item__image img").attr("src");
						if (this.images && this.images.length) {
							imgUrl = this.images[0].url;
						}
						$product.find(".item__image img").attr("src", imgUrl);
						$product.find(".item__name").html(this.name);
						$product.find(".item__code").html(this.code);
						$product.find(".item__price").html(this.price.formattedValue);
						$product.find(".js-set-pdp-wrapping-product-code").data({productCode: this.code, imgUrl: imgUrl});
						$product.appendTo($productList);
					});
					ACC.colorbox.open(title, {
						html: $productList,
						className: "wrapping-list-overlay",
						width: '95%',
						height: '95%',
						maxWidth: '960px',
						maxHeight: '960px'
					});
				},
				error: function (jqXHR, textStatus, errorThrown) {
					alert("Failed to load wrappings. Error details [" + textStatus + ", " + errorThrown + "]");
				}
			});
			return false;
		});

		$("#Size").change(function () {
			changeOnVariantOptionSelection($("#Size option:selected"));
		});

		$("#variant").change(function () {
			changeOnVariantOptionSelection($("#variant option:selected"));
		});

		$(".selectPriority").change(function () {
			window.location.href = $(this[this.selectedIndex]).val();
		});

		function changeOnVariantOptionSelection(optionSelected) {
			window.location.href = optionSelected.attr('value');
		}
	},

	bindVariantOptions: function () {
		ACC.productDetail.bindCurrentStyle();
		ACC.productDetail.bindCurrentSize();
		ACC.productDetail.bindCurrentType();
	},

	bindCurrentStyle: function () {
		var currentStyle = $("#currentStyleValue").data("styleValue");
		var styleSpan = $(".styleName");
		if (currentStyle != null) {
			styleSpan.text(": " + currentStyle);
		}
	},

	bindCurrentSize: function () {
		var currentSize = $("#currentSizeValue").data("sizeValue");
		var sizeSpan = $(".sizeName");
		if (currentSize != null) {
			sizeSpan.text(": " + currentSize);
		}
	},

	bindCurrentType: function () {
		var currentSize = $("#currentTypeValue").data("typeValue");
		var sizeSpan = $(".typeName");
		if (currentSize != null) {
			sizeSpan.text(": " + currentSize);
		}
	}
};
ACC.mydesigns = {
	_autoload: [
		"bindRemoveDesignForm"
	],

	bindRemoveDesignForm: function() {
		$(document).on('submit.removeDesignForm', '.js-mydesigns-remove-form', function(event) {
			var $form = $(event.currentTarget);
			var confirmMsg = $form.data('confirmMsg');

			if (!confirm(confirmMsg)) {
				event.preventDefault();
			}
		});
	},

	unbindRemoveDesignForm: function() {
		$(document).off('.removeDesignForm');
	}
};
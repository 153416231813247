var oDoc = document;

ACC.navigation = {

    _autoload: [
        "offcanvasNavigation",
        "myAccountNavigation",
        "orderToolsNavigation"
    ],

    offcanvasNavigation: function(){

        enquire.register("screen and (max-width:"+screenSmMax+")", {

            match : function() {

                $(document).on("click",".js-enquire-offcanvas-navigation .js-enquire-has-sub .js_nav__link--drill__down",function(e){
                    e.preventDefault();
                    $(".js-userAccount-Links").hide();
                    $(".js-enquire-offcanvas-navigation ul.js-offcanvas-links").addClass("active");
                    $(".js-enquire-offcanvas-navigation .js-enquire-has-sub").removeClass("active");
                    $(this).parent(".js-enquire-has-sub").addClass("active");
                });


                $(document).on("click",".js-enquire-offcanvas-navigation .js-enquire-sub-close",function(e){
                    e.preventDefault();
                    $(".js-userAccount-Links").show();
                    $(".js-enquire-offcanvas-navigation ul.js-offcanvas-links").removeClass("active");
                    $(".js-enquire-offcanvas-navigation .js-enquire-has-sub").removeClass("active");
                });

            },

            unmatch : function() {

                $(".js-userAccount-Links").show();
                $(".js-enquire-offcanvas-navigation ul.js-offcanvas-links").removeClass("active");
                $(".js-enquire-offcanvas-navigation .js-enquire-has-sub").removeClass("active");

                $(document).off("click",".js-enquire-offcanvas-navigation .js-enquire-has-sub > a");
                $(document).off("click",".js-enquire-offcanvas-navigation .js-enquire-sub-close");


            }


        });

    },

    myAccountNavigation: function(){
        //Add the order form img in the navigation
        $('.nav-form').html($('<span class="glyphicon glyphicon-list-alt"></span>'));


        var aAcctData = [];
        var sSignBtn = "";

        //my account items
        var oMyAccountData = $(".accNavComponent");

        //the my Account hook for the desktop
        var oMMainNavDesktop = $(".js-secondaryNavAccount > ul");

        if(oMyAccountData){
            var aLinks = oMyAccountData.find("a");
            aLinks.each(function(){
	            aAcctData.push({link: this.href, text: this.title});
            });
        }

	    var navClose = '';
        navClose += '<div class="close-nav">';
        navClose += '<button type="button" class="js-toggle-sm-navigation btn"><span class="glyphicon glyphicon-remove"></span></button>';
        navClose += '</div>';

        //create Sign In/Sign Out Button
        if($(".liOffcanvas a") && $(".liOffcanvas a").length > 0){
            sSignBtn += '<li class=\"auto liUserSign\" ><a class=\"userSign\" href=\"' + $(".liOffcanvas a")[0].href + '\">' + $(".liOffcanvas a")[0].innerHTML + '</a></li>';
        }

        //create Welcome User + expand/collapse and close button
        //This is for mobile navigation. Adding html and classes.
        var oUserInfo = $(".nav__right ul li.logged_in");
        //Check to see if user is logged in
        if(oUserInfo && oUserInfo.length === 1)
        {
            var sUserBtn = '';
            sUserBtn += '<li class=\"auto \">';
            sUserBtn += '<div class=\"userGroup\">';
            sUserBtn += '<div class=\"userName\">' + oUserInfo[0].innerHTML + '</div>';
            if(aAcctData.length > 0){
                sUserBtn += '<a class=\"collapsed\" id="signedInUserOptionsToggle"  data-target=\".offcanvasGroup1\">';
                sUserBtn += '</a>';
            }
            sUserBtn += '</div>';
            sUserBtn += navClose;

            $('.js-sticky-user-group').html(sUserBtn);


            $('.js-userAccount-Links').append($('<li class="auto"><div class="myAccountLinksContainer js-myAccountLinksContainer"></div></li>'));


            //FOR DESKTOP
            var myAccountHook = $('<a class="myAccountLinksHeader collapsed js-myAccount-toggle login-link" data-toggle="collapse" data-parent=".nav__right" href="#accNavComponentDesktopOne"><i class="icon icon-login"></i><span class="login-link__label text-nowrap">' + oUserInfo.data("welcomeText") + '</span></a>');
            myAccountHook.insertBefore(oMyAccountData);

            //FOR MOBILE
            //create a My Account Top link for desktop - in case more components come then more parameters need to be passed from the backend
            var myAccountHook = [];
            myAccountHook.push('<div class="sub-nav">');
            myAccountHook.push('<a id="signedInUserAccountToggle" class=\"myAccountLinksHeader icon icon-login collapsed js-myAccount-toggle\" data-toggle=\"collapse\" data-target=".offcanvasGroup2">');
	        myAccountHook.push('<div class="login-welcome-text">' + oUserInfo.data("welcomeText") + '</div>');
            myAccountHook.push('<span class="glyphicon glyphicon-chevron-down myAcctExp"></span>');
            myAccountHook.push('</a>');
            myAccountHook.push('</div>');

            $('.js-myAccountLinksContainer').append(myAccountHook.join(''));

            //add UL element for nested collapsing list
            $('.js-myAccountLinksContainer').append($('<ul data-trigger="#signedInUserAccountToggle" class="offcanvasGroup2 offcanvasNoBorder collapse js-nav-collapse-body subNavList js-myAccount-root sub-nav"></ul>'));


            //offcanvas items
            //TODO Follow up here to see the output of the account data in the offcanvas menu
	        var $logoutListItemMobile;
	        for(var i = 0; i < aAcctData.length; i++){
                var oLink = oDoc.createElement("a");
                oLink.title = aAcctData[i].text;
                oLink.href = aAcctData[i].link;
                oLink.innerHTML = aAcctData[i].text;

                var oListItem = oDoc.createElement("li");
                oListItem.appendChild(oLink);
                oListItem = $(oListItem);
                oListItem.addClass("auto ");

		        // if current item == logout link  => store it temporarily and exclude it from the list
		        if(oLink.href.indexOf('logout')>=0) {
			        $logoutListItemMobile = oListItem;
		        }
		        else
		        {
		            $('.js-myAccount-root').append(oListItem);
		        }
            }
	        // move logout btn to the end of the nav
	        $('.js-myAccount-root').append($logoutListItemMobile);

        } else {
            var navButtons = (sSignBtn.substring(0, sSignBtn.length - 5) + navClose) + '</li>';
            $('.js-sticky-user-group').html(navButtons);
        }


        //desktop
        var $logoutListItemDesktop;
        for(var i = 0; i < aAcctData.length; i++){
            var oLink = oDoc.createElement("a");
            oLink.title = aAcctData[i].text;
            oLink.href = aAcctData[i].link;
            oLink.innerHTML = aAcctData[i].text;

            var oListItem = oDoc.createElement("li");
            oListItem.appendChild(oLink);
            oListItem = $(oListItem);
            oListItem.addClass("auto col-md-4");

            // if current item == logout link  => store it temporarily and exclude it from the list
	        if(oLink.href.indexOf('logout')>=0) {
		        $logoutListItemDesktop = oListItem;
	        }
	        else
            {
                oMMainNavDesktop.get(0).appendChild(oListItem.get(0));
            }
        }
        // move logout btn to the end of the nav
        oMMainNavDesktop.append($logoutListItemDesktop);

	    // set active nav item from contextPath (in myAccount nav only)
	    $('.js-secondaryNavAccount, .js-userAccount-Links').find('a[href$="' + location.pathname.replace(ACC.config.encodedContextPath,"") + '"]').parent('li').addClass('active');


	    //hide and show content areas for desktop
        $('.js-secondaryNavAccount').on('shown.bs.collapse', function () {

            if($('.js-secondaryNavCompany').hasClass('in')){
                $('.js-myCompany-toggle').click();
            }

        });

        $('.js-secondaryNavCompany').on('shown.bs.collapse', function () {

            if($('.js-secondaryNavAccount').hasClass('in')){
                $('.js-myAccount-toggle').click();
            }

        });

        //change icons for up and down
        $('.js-nav-collapse-body').on('hidden.bs.collapse', function(e){

            var target = $(e.target);
            var targetSpan = target.attr('data-trigger') + ' > span';
            if(target.hasClass('in')) {
                $(targetSpan).removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up');
            }
            else {
                $(targetSpan).removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down');
            }

        });

        $('.js-nav-collapse-body').on('show.bs.collapse', function(e){
            var target = $(e.target)
            var targetSpan = target.attr('data-trigger') + ' > span';
            if(target.hasClass('in')) {
                $(targetSpan).removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down');

            }
            else {
                $(targetSpan).removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up');
            }

        });

        //$('.offcanvasGroup1').collapse();


    },

    orderToolsNavigation: function(){
        $('.js-nav-order-tools').on('click', function(e){
            $(this).toggleClass('js-nav-order-tools--active');
        });
    }
};

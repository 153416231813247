ACC.tabs = {

	_autoload: [
		["bindTabs", $(".js-tabs").length > 0],
		"bindScrollToNameOrId",
		"bindForwardTabToggle"
	],

	/**
	 * configuration for Accessible Tabs - jQuery plugin
	 */
	bindTabs: function () {
		$e = $(".js-tabs");
		var tabs = $e.accessibleTabs({
			tabhead: '.tabhead',
			tabbody: '.tabbody',
			fx: 'show',
			fxspeed: 0,
			currentClass: 'active',
			autoAnchor: true
		});


		$e.on("click", ".tabhead", function (e) {
			e.preventDefault();

			if ($(this).hasClass("active")) {
				$(this).removeClass("active");
			} else {
				$(this).parents(".js-tabs").children(".tabs-list").find("a[href='" + "#" + $(this).attr("id") + "']").click();

				var offset = $(this).offset().top;
				$("body,html").scrollTop(offset)
			}

		});

		$(document).on("click", ".js-openTab", function () {
			tabs.showAccessibleTabSelector($(this).attr("href"));
		})
	},

	/**
	 * configuration for bootstrap tabs
	 * add data-scrollto attribute to tab head elements and give optional id or anchor name as value
	 * if no value is given, the tab-pane with id from href is chosen
	 */
	bindScrollToNameOrId: function () {
		$(document).on('shown.bs.tab.scrollToNameOrId', '[data-toggle="tab"][data-scrollto]', function (event) {
			var $this = $(event.currentTarget),
				scrollID = $this.data('scrollto'),
				$scrollTarget;
			// use href as fallback
			if (!scrollID.length)
				scrollID = $this.attr('data-target').substr(1) || $this.attr('href').substr(1);
			$scrollTarget = $('a[name="' + scrollID + '"], #' + scrollID);
			// only scroll, if view is below the target or target is out of view
			if ($scrollTarget.length && ($scrollTarget.offset().top < $(window).scrollTop() || $scrollTarget.offset().top > $(window).scrollTop() + $(window).height()))
				$('html, body').animate({scrollTop: $scrollTarget.offset().top});
			// console.log(scrollID, $scrollTarget);
		});
	},
	unbindScrollToNameOrId: function () {
		$(document).off('.scrollToNameOrId');
	},

	/**
	 * forward a tab click
	 * use element with data-forward="tab" and href to forward a click to the corresponding tab head
	 */
	bindForwardTabToggle: function () {
		$(document).on('click.forwardTabToggle', '[data-forward="tab"]', function (event) {
			var $this = $(event.currentTarget),
				tabID = $this.attr('data-target') || $this.attr('href'),
				$tab = $('[data-toggle="tab"][data-target="' + tabID + '"], [data-toggle="tab"][href="' + tabID + '"]');
			if ($tab.length === 1) {
				event.preventDefault();
				$tab.tab('show');
			}
		});
	},
	unbindForwardTabToggle: function () {
		$(document).off('.forwardTabToggle');
	}

};

ACC.configuratorValidation = {
	validateFileSize: function (file, maxFileSize) {
		if ((file.size / 1024) > maxFileSize) {
			return false;
		}

		return true;
	},

	validateImageDimensions: function (image, minWidth, minHeight) {
		var imageHeight = image.naturalHeight;
		var imageWidth = image.naturalWidth;

		if ((imageWidth < minWidth) || (imageHeight < minHeight)) {
			return false;
		}

		return true;
	},

	validateBackgroundImageFileSize: function (file, fileReader) {
		if (ACC.configuratorValidation.validateFileSize(file, ACC.configuratorConfig.properties.backgroundImageMaxFileSize)) {
			fileReader.readAsDataURL(file);
		}
		else {
			var $uploadBackgroundBtn = $(ACC.configuratorConfig.selectors.uploadBackgroundBtn);
			var tooltipErrorMsgFileSize = $uploadBackgroundBtn.data(ACC.configuratorConfig.dataAttrNames.tooltipErrorMsgFileSize);
			ACC.global.showErrorMsgTooltip($uploadBackgroundBtn, tooltipErrorMsgFileSize);

			if ($('.cr-boundary').length) {
				$('.cr-boundary').removeClass('faded-out');
			}
		}
	},

	validateLogoFileSize: function (file, fileReader) {
		if (ACC.configuratorValidation.validateFileSize(file, ACC.configuratorConfig.properties.logoMaxFileSize)) {
			fileReader.readAsDataURL(file);
		}
		else {
			var $uploadLogoBtn = $(ACC.configuratorConfig.selectors.uploadLogoBtn);
			var tooltipErrorMsgFileSize = $uploadLogoBtn.data(ACC.configuratorConfig.dataAttrNames.tooltipErrorMsgFileSize);
			ACC.global.showErrorMsgTooltip($uploadLogoBtn, tooltipErrorMsgFileSize);
		}
	},

	/**
	 * Checks if background image and/or logo are missing in the filled temp formData object.
	 *
	 * @returns {boolean} invalid => false, valid => true
	 */
	validateFormData: function () {
		var $addToCartBtn = $(ACC.configuratorConfig.selectors.addToCartBtn);
		var tooltipErrorMsgMissingBackground = $addToCartBtn.data(ACC.configuratorConfig.dataAttrNames.tooltipErrorMsgMissingBackground);
		var tooltipErrorMsgMissingBackgroundLogo = $addToCartBtn.data(ACC.configuratorConfig.dataAttrNames.tooltipErrorMsgMissingBackgroundLogo);
		var tooltipErrorMsgMissingLogo = $addToCartBtn.data(ACC.configuratorConfig.dataAttrNames.tooltipErrorMsgMissingLogo);

		// check if backgroundImage and logo are uploaded
		if (ACC.configurator.properties.mode === 'advanced') {
			var formDataDesignValue = ACC.configurator.properties.tempFormDataObject.backgroundImage.src;
			var formDataLogoValue = ACC.configurator.properties.tempFormDataObject.logo.src;

			if ((formDataDesignValue === '') && (formDataLogoValue === '')) {
				ACC.global.showErrorMsgTooltip($addToCartBtn, tooltipErrorMsgMissingBackgroundLogo);
				return false;
			}
			else if ((formDataDesignValue === '') && (formDataLogoValue !== '')) {
				ACC.global.showErrorMsgTooltip($addToCartBtn, tooltipErrorMsgMissingBackground);
				return false;
			}
			else if ((formDataDesignValue !== '') && (formDataLogoValue === '')) {
				ACC.global.showErrorMsgTooltip($addToCartBtn, tooltipErrorMsgMissingLogo);
				return false;
			}
		}
		else {
			if (ACC.configurator.properties.tempFormDataObject.logo.src === '') {
				ACC.global.showErrorMsgTooltip($addToCartBtn, tooltipErrorMsgMissingLogo);
				return false;
			}
		}

		return true;
	},

	/**
	 * Validates the entered amount. If empty then show an error msg. Otherwise show the configurator.
	 * @param amount
	 */
	validateAmount: function (amount, $elementWithMessage) {
		if (amount.length === 0) {
			var tooltipErrorMsgMissingAmount = $elementWithMessage.data(ACC.configuratorConfig.dataAttrNames.tooltipErrorMsgMissingAmount);
			ACC.global.showErrorMsgTooltip($elementWithMessage, tooltipErrorMsgMissingAmount);
			return false;
		}

		return true;
	},

	/**
	 * Checks the image file formats which are configured via impex.
	 *
	 * @param file
	 * @returns {boolean} invalid => false, valid => true
	 */
	validateFileFormat: function (file, $uploadButton) {
		// if no file is selected
		if (file === null) {
			return false;
		}

		var regexPattern = $uploadButton.data(ACC.configuratorConfig.dataAttrNames.imageFileFormatsRegexPattern);

		// check file format with regex
		var regex = new RegExp(regexPattern);
		var array = regex.exec(file.type);

		if (array !== null && array.index > 0) {
			return true;
		}
		else {
			var tooltipErrorMsg = $uploadButton.data(ACC.configuratorConfig.dataAttrNames.tooltipErrorMsgInvalidFileFormat);
			ACC.global.showErrorMsgTooltip($uploadButton, tooltipErrorMsg);
		}
	}
};

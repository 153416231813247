ACC.configuratorConfig = {
	configuratorDefaultFontFamily: '"Coop Webfont", Helvetica, Arial, sans-serif',

	urls: {
		initialConfig: '/config/getConfigSettings',
		restoreConfig: '/config/getConfigInfos',
		restoreOrderEntryConfig: '/config/getConfigInfosForOrderEntry'
	},

	properties: {
		backgroundImageMaxFileSize: 5120,
		backgroundImageMinHeight: 665, // will be overwritten when settings are imported
		backgroundImageMinWidth: 1058, // will be overwritten when settings are imported
		backgroundImageQualityValidationBuffer: 1.05, // background image quality validation: the image width will be increased by 5% in order to give the customer an additional range for zooming the image
		configuratorMaskHeight: 665, // will be overwritten when settings are imported
		configuratorMaskWidth: 1058, // will be overwritten when settings are imported
		logoMaxFileSize: 5120,
		logoMinZoom:1,
		//should not be needed
		logoMinHeight: 150,
		logoMinWidth: 150
	},

	dataAttrNames: {
		dataConfiguratorFormId: 'configuratorFormId',
		designTemplatesIndexJson: 'designTemplatesIndexJson',
		designTemplatesUrl: 'designTemplatesUrl',
		imageFileFormatsRegexPattern: 'imageFileFormatsRegexPattern',
		tooltipErrorMsgInvalidFileFormat: 'tooltipErrorMsgInvalidFileFormat',
		tooltipErrorMsgDimensions: 'tooltipErrorMsgDimensions',
		tooltipErrorMsgFileSize: 'tooltipErrorMsgSize',
		tooltipErrorMsgMissingAmount: 'tooltipErrorMsgMissingAmount',
		tooltipErrorMsgMissingBackground: 'tooltipErrorMsgMissingFormdataBackground',
		tooltipErrorMsgMissingBackgroundLogo: 'tooltipErrorMsgMissingFormdataBackgroundLogo',
		tooltipErrorMsgMissingLogo: 'tooltipErrorMsgMissingFormdataLogo'
	},

	selectors: {
		addToCartBtn: '.js_configurator-addToCart-btn',
		configuratorAmount: '.js_card-configurator-amount',
		configuratorContent: '.card-configurator-content',
		configuratorLink: '.js_card-configurator-link',
		configuratorDesigns: '.card-configurator-designs',
		configuratorDesignsList: '#card-configurator-designs-list',
		configuratorDesignsListItem: '.card-configurator-designs-list__item',
		configuratorDesignsListImage: '.card-configurator-designs-list__image',
		configuratorDesignsListTitle: '.card-configurator-designs-list__title',
		cardConfiguratorMydesigns: '.card-configurator-mydesigns',
		cardConfigurator: '.card-configurator',
		cardValue: '.card-configurator-value',
		checkboxShowValue: '.js_checkbox-show-value input[type=checkbox]',
		checkIcon: '.icon-checked-footer',
		croppieBoundary: '.card-configurator .cr-boundary:not(.card-configurator-customLogo .cr-boundary)',
		croppieViewport: '.card-configurator .cr-viewport:not(.card-configurator-customLogo .cr-viewport)',
		droppableArea: '.js_card-configurator-droppable-area',
		hiddenUploadBackgroundBtn: '.js_file-chooser-background',
		hiddenUploadLogoBtn: '.js_file-chooser-logo',
		imageMask: '.js_card-configurator-imageMask',
		logoAlignment: '.logo-alignment',
		logoAlignmentRadiobutton: '.js_logo-alignment-radiobutton',
		logoImgElement: '.js_card-configurator-customLogo',
		logoWrapper: '.card-configurator-customLogo',
		logoCroppieArea: '.js_card-configurator-logo-croppie-area',
		popupID: '#popup-card-configurator',
		sliderLabelInfoIcon: '.card-configurator .slider-label span',
		sliderWrap: '.card-configurator .cr-slider-wrap',
		sliderWrapDisabled: '.card-configurator .cr-slider-wrap.disabled',
		uploadBackgroundBtn: '.js_card-configurator-uploadBackground-btn',
		uploadLogoBtn: '.js_card-configurator-uploadLogo-btn'
	},

	cleanTempFormDataObject: {
		backgroundImage: {
			src: '',
			x: 0,
			y: 0,
			zoomFactor: 1,
			originalWidth: 1
		},
		logo: {
			src: '',
			alignment: 'left'
		},
		showCardValue: true
	},
	closeBtnSnippet: "<button type=\"button\" class=\"configurator-closeBtn\"><span class=\"glyphicon glyphicon-remove\"></span></button>"
};

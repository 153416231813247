ACC.langcurrency = {

	_autoload: [
		"bindLangCurrencySelector"
	],

	bindLangCurrencySelector: function (){

        $('a[class^="js-language-select"]').click(function () {
			
			$("#lang-form input[name=code]").val($(this).text());
			$('#lang-form').submit();
	    });
		$('#currency-selector').change(function(){
			$('#currency-form').submit();
		});
	}
};
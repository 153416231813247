ACC.address = {

	_autoload: [
		"bindToChangeAddressButton",
		"bindCreateUpdateAddressForm",
		"bindSuggestedDeliveryAddresses",
		"bindCountrySpecificAddressForms",
		"showAddressFormButtonPanel",
		"bindViewAddressBook",
		"bindEditBillingAddress",
		"bindDeliveryAddressType",
		"bindToColorboxClose",
		"showRemoveAddressFromBookConfirmation",
		"backToListAddresses",
		"removeEmailInputValue",
		["bindTogglePostbox", $('#b2bgkAddressForm').length > 0]
	],

	spinner: $("<img src='" + ACC.config.commonResourcePath + "/images/spinner.gif' />"),
	addressID: '',

	handleChangeAddressButtonClick: function () {


		ACC.address.addressID = ($(this).data("address")) ? $(this).data("address") : '';
		$('#summaryDeliveryAddressFormContainer').show();
		$('#summaryOverlayViewAddressBook').show();
		$('#summaryDeliveryAddressBook').hide();


		$.getJSON(getDeliveryAddressesUrl, ACC.address.handleAddressDataLoad);
		return false;
	},

	handleAddressDataLoad: function (data) {
		ACC.address.setupDeliveryAddressPopupForm(data);

		// Show the delivery address popup
		ACC.colorbox.open("", {
			inline: true,
			href: "#summaryDeliveryAddressOverlay",
			overlayClose: false,
			onOpen: function () {
				// empty address form fields
				ACC.address.emptyAddressForm();
				$(document).on('change', '#saveAddress', function () {
					var saveAddressChecked = $(this).prop('checked');
					$('#defaultAddress').prop('disabled', !saveAddressChecked);
					if (!saveAddressChecked) {
						$('#defaultAddress').prop('checked', false);
					}
				});
			}
		});

	},

	setupDeliveryAddressPopupForm: function (data) {
		// Fill the available delivery addresses
		$('#summaryDeliveryAddressBook').html($('#deliveryAddressesTemplate').tmpl({addresses: data}));
		// Handle selection of address
		$('#summaryDeliveryAddressBook button.use_address').click(ACC.address.handleSelectExistingAddressClick);
		// Handle edit address
		$('#summaryDeliveryAddressBook button.edit').click(ACC.address.handleEditAddressClick);
		// Handle set default address
		$('#summaryDeliveryAddressBook button.default').click(ACC.address.handleDefaultAddressClick);
	},

	emptyAddressForm: function () {
		var options = {
			url: getDeliveryAddressFormUrl,
			data: {addressId: ACC.address.addressID, createUpdateStatus: ''},
			type: 'GET',
			success: function (data) {
				$('#summaryDeliveryAddressFormContainer').html(data);
				ACC.address.bindCreateUpdateAddressForm();
			}
		};

		$.ajax(options);
	},

	handleSelectExistingAddressClick: function () {
		var addressId = $(this).attr('data-address');
		$.postJSON(setDeliveryAddressUrl, {addressId: addressId}, ACC.address.handleSelectExitingAddressSuccess);
		return false;
	},

	handleEditAddressClick: function () {

		$('#summaryDeliveryAddressFormContainer').show();
		$('#summaryOverlayViewAddressBook').show();
		$('#summaryDeliveryAddressBook').hide();

		var addressId = $(this).attr('data-address');
		var options = {
			url: getDeliveryAddressFormUrl,
			data: {addressId: addressId, createUpdateStatus: ''},
			target: '#summaryDeliveryAddressFormContainer',
			type: 'GET',
			success: function (data) {
				ACC.address.bindCreateUpdateAddressForm();
				ACC.colorbox.resize();
			},
			error: function (xht, textStatus, ex) {
				alert("Failed to update cart. Error details [" + xht + ", " + textStatus + ", " + ex + "]");
			}
		};

		$(this).ajaxSubmit(options);
		return false;
	},

	handleDefaultAddressClick: function () {
		var addressId = $(this).attr('data-address');
		var options = {
			url: setDefaultAddressUrl,
			data: {addressId: addressId},
			type: 'GET',
			success: function (data) {
				ACC.address.setupDeliveryAddressPopupForm(data);
			},
			error: function (xht, textStatus, ex) {
				alert("Failed to update address book. Error details [" + xht + ", " + textStatus + ", " + ex + "]");
			}
		};

		$(this).ajaxSubmit(options);
		return false;
	},

	handleSelectExitingAddressSuccess: function (data) {
		if (data != null) {
			ACC.refresh.refreshPage(data);
			ACC.colorbox.close();
		}
		else {
			alert("Failed to set delivery address");
		}
	},

	bindCreateUpdateAddressForm: function () {
		$('.create_update_address_form').each(function () {
			var options = {
				type: 'POST',
				beforeSubmit: function () {
					$('#checkout_delivery_address').block({message: ACC.address.spinner});
				},
				success: function (data) {
					$('#summaryDeliveryAddressFormContainer').html(data);
					var status = $('.create_update_address_id').attr('status');
					if (status != null && "success" === status.toLowerCase()) {
						ACC.refresh.getCheckoutCartDataAndRefreshPage();
						ACC.colorbox.close();
					}
					else {
						ACC.address.bindCreateUpdateAddressForm();
						ACC.colorbox.resize();
					}
				},
				error: function (xht, textStatus, ex) {
					alert("Failed to update cart. Error details [" + xht + ", " + textStatus + ", " + ex + "]");
				},
				complete: function () {
					$('#checkout_delivery_address').unblock();
				}
			};

			$(this).ajaxForm(options);
		});
	},

	refreshDeliveryAddressSection: function (data) {
		$('.summaryDeliveryAddress').replaceWith($('#deliveryAddressSummaryTemplate').tmpl(data));

	},

	bindSuggestedDeliveryAddresses: function () {
		var status = $('.add_edit_delivery_address_id').attr('status');
		if (status != null && "hasSuggestedAddresses" == status) {
			ACC.address.showSuggestedAddressesPopup();
		}
	},

	showSuggestedAddressesPopup: function () {

		ACC.colorbox.open("", {
			href: "#popup_suggested_delivery_addresses",
			inline: true,
			overlayClose: false,
			width: 525,
		});
	},

	bindCountrySpecificAddressForms: function () {
		$(document).on("change", '#countrySelector select', function () {
			var options = {
				'addressCode': '',
				'countryIsoCode': $(this).val()
			};
			ACC.address.displayCountrySpecificAddressForm(options, ACC.address.showAddressFormButtonPanel);
		})

	},

	showAddressFormButtonPanel: function () {
		if ($('#countrySelector :input').val() !== '') {
			$('#addressform_button_panel').show();
		}
	},

	bindToColorboxClose: function () {
		$(document).on("click", ".closeColorBox", function () {
			ACC.colorbox.close();
		})
	},


	displayCountrySpecificAddressForm: function (options, callback) {
		$.ajax({
			url: ACC.config.encodedContextPath + '/my-account/addressform',
			async: true,
			data: options,
			dataType: "html",
			beforeSend: function () {
				$("#i18nAddressForm").html(ACC.address.spinner);
			}
		}).done(function (data) {
			$("#i18nAddressForm").html($(data).html());
			if (typeof callback == 'function') {
				callback.call();
			}
		});
	},

	bindToChangeAddressButton: function () {
		$(document).on("click", '.summaryDeliveryAddress .editButton', ACC.address.handleChangeAddressButtonClick);
	},

	bindDeliveryAddressType: function () {
		$(document).on("change", ".js-delivery-addresstype .js-addresstype-radio", function (e) {
			var $radio = $(e.currentTarget);
			var showAddressFields = $radio.val() === "NEWADDRESS";

			$(".js-delivery-address-fields").toggle(showAddressFields);

			//// show info messages for different delivery addresstypes
			// 1. hide all info messages
			$radio.closest('.js-delivery-addresstype').find('.info-msg:not(.js-disabled-info-msg)').addClass('hidden');
			// 2. show info message for selected radio button
			$radio.closest('.radio').find('.info-msg').removeClass('hidden');
		});

		// trigger the correct onchange event
		$('.js-addresstype-radio[type=radio]:checked').trigger('change');
	},

	bindViewAddressBook: function () {

		$(document).on("click", ".js-address-book", function (e) {
			e.preventDefault();
			var $link = $(e.currentTarget),
				$addressBookRadio = $link.closest(".radio").find(".js-addresstype-radio"),
				popupTitle = $link.data("popupTitle");

			$addressBookRadio.prop("checked", true).change();

			ACC.colorbox.open(popupTitle, {
				href: "#addressbook",
				inline: true,
				width: "380px"
			});

		})


		$(document).on("click", '#summaryOverlayViewAddressBook', function () {
			$('#summaryDeliveryAddressFormContainer').hide();
			$('#summaryOverlayViewAddressBook').hide();
			$('#summaryDeliveryAddressBook').show();
			ACC.colorbox.resize();
		});
	},

	bindEditBillingAddress: function () {
		$(document).on("click", ".js-billing-address-edit", function (event) {
			event.preventDefault();
			var $link = $(event.currentTarget),
				url = $link.attr("href"),
				popupTitle = $link.data("popupTitle"),
				popupClass = "edit-billing-address-popup";

			var ajaxFormCallback = function (responseData) {
				var $responseData = $(responseData),
					$popupContent = $("." + popupClass).find("#cboxLoadedContent");
				$popupContent.html($responseData);
				$popupContent.find(".js-edit-billing-address-form").ajaxForm({success: ajaxFormCallback});
				ACC.colorbox.resize();
			};

			var cbox = ACC.colorbox.open(popupTitle,
				{
					className: popupClass,
					width: 500,
					href: url,
					onComplete: function () {
						var $form = $("." + popupClass).find(".js-edit-billing-address-form");
						$form.ajaxForm({success: ajaxFormCallback});
						ACC.colorbox.config.onComplete.call(cbox);
					},
					onClosed: function () {
						window.location.reload();
					}
				}
			);
		});
	},

	bindTogglePostbox: function (config) {
		var selectors = {
			toggle: '.js-toggle-postbox',
			section: '.js-postbox-form-section',
			copy: [
				{from: 'input[name=postcode]', to: 'input[name=poboxPostalcode]'},
				{from: 'input[name=townCity]', to: 'input[name=poboxTown]'}
			]
		};
		$.extend(true, selectors, config);

		$(document).on('change.togglePostbox', selectors.toggle, function (event) {
			var postboxCheckboxState = $(event.currentTarget).is(':checked');
			var postboxInitiallyChecked = $(event.currentTarget).is('[checked]');
			var $postboxFormSection = $(selectors.section);
			var $formRef = $postboxFormSection.closest('form');

			// show/hide postbox section
			$postboxFormSection.toggleClass('hidden', !postboxCheckboxState);

			// copy values
			if (postboxCheckboxState && !postboxInitiallyChecked) {
				for (var i = 0, iLength = selectors.copy.length; i < iLength; i++) {
					var $source = $formRef.find(selectors.copy[i].from),
						$target = $formRef.find(selectors.copy[i].to);
					// only copy the values when postbox fields are empty
					if ($target.val() === '') {
						$target.val($source.val());
					}
				}
			}
		});

		// set the correct initial state
		$(selectors.toggle).trigger('change');
	},

	unbindTogglePostbox: function () {
		$(document).off('.togglePostbox');
	},

	showRemoveAddressFromBookConfirmation: function () {
		$(document).on("click", ".removeAddressFromBookButton", function () {
			var addressId = $(this).data("addressId");
			var popupTitle = $(this).data("popupTitle");

			ACC.colorbox.open(popupTitle, {
				inline: true,
				height: false,
				href: "#popup_confirm_address_removal_" + addressId
			});

		})
	},

	backToListAddresses: function () {
		$(".addressBackBtn").on("click", function () {
			var sUrl = $(this).data("backToAddresses");
			window.location = sUrl;
		});
	},

	removeEmailInputValue: function () {
		$('#INVOICEPOST').change(function() {
			$("[data-invoice-email]").val("");
		});
	}
};

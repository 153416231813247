ACC.configurator = {
	_autoload: [
		"removeDuplicatePopups",
		"bindConfiguratorButton"
	],

	properties: {
		amount: '',
		backgroundImageReplaced: false,
		$configuratorCurrentForm: $(),
		configurationChanged: false,
		configurationRestored: false,
		configurationPartiallyRestored: false, // true: when configuration is broken and cannot be restored completely
		entryNumber: '',
		restoringBackgroundImage: false, // is set to true when the configurator starts to restore the backgroundImage
		restoringLogo: false, // is set to true when the configurator starts to restore the logo
		orderEntryNumber: '',
		orderCode: '',
		logoReplaced: false,
		mode: '', // simple (only logo) or advanced (backgroundImage and logo)
		popupTitle: '',
		productCode: '',
		responseData: {},
		sliderObject: {},
		tempFormDataObject: {},
		updateTimerId: 0
	},

	removeDuplicatePopups: function () {
		var $configuratorPopups = $(ACC.configuratorConfig.selectors.cardConfigurator);
		var numberOfConfiguratorPopups = $configuratorPopups.length;
		while (numberOfConfiguratorPopups > 1) {
			$configuratorPopups.last().remove();
			numberOfConfiguratorPopups--;
		}
	},

	storeProductInformationFromDataAttributes: function ($element) {
		ACC.configurator.properties.productCode = $element.data('productCode');
		ACC.configurator.properties.entryNumber = $element.data('entryNumber');
		ACC.configurator.properties.orderEntryNumber = $element.data('orderEntryNumber');
		ACC.configurator.properties.orderCode = $element.data('orderCode');
	},

	isConfiguratorForCartProduct: function () {
		return ACC.configurator.properties.entryNumber !== undefined && ACC.configurator.properties.entryNumber !== '';
	},

	isConfiguratorForMyDesignsProduct: function () {
		return !ACC.configurator.isConfiguratorForCartProduct()
			&& ACC.configurator.properties.productCode !== undefined && ACC.configurator.properties.productCode !== ''
			&& ACC.configurator.properties.orderCode !== undefined && ACC.configurator.properties.orderCode !== ''
			&& ACC.configurator.properties.orderEntryNumber !== undefined && ACC.configurator.properties.orderEntryNumber !== '';
	},

	isConfiguratorForOrderEntryProduct: function () {
		return !ACC.configurator.isConfiguratorForMyDesignsProduct()
			&& ACC.configurator.properties.orderCode !== undefined && ACC.configurator.properties.orderCode !== ''
			&& ACC.configurator.properties.orderEntryNumber !== undefined && ACC.configurator.properties.orderEntryNumber !== '';
	},

	bindConfiguratorButton: function () {
		$(document).on("click", ACC.configuratorConfig.selectors.configuratorLink, ACC.configurator.configuratorButtonClickHandler);
	},

	unbindConfiguratorButton: function () {
		$(document).off("click", ACC.configuratorConfig.selectors.configuratorLink);
	},

	configuratorButtonClickHandler: function (event) {
		event.preventDefault();

		var $configuratorLink = $(event.currentTarget);
		ACC.configurator.properties.popupTitle = $(ACC.configuratorConfig.selectors.popupID).data('popupTitle') || '';
		ACC.configurator.storeProductInformationFromDataAttributes($configuratorLink);

		// if it is not allowed to put a configured card in the cart because of digital cards in the current cart
		if (ACC.config.isOrderWithDigitalFulfillment === true && !ACC.configurator.isConfiguratorForOrderEntryProduct()) {
			var title = ACC.config.mixedCart.Physical2DigitalTitle;
			var message = '<div class="alert alert-danger">' + ACC.config.mixedCart.Physical2DigitalMessage + '</div>';
			// show info msg in popup
			ACC.colorbox.open(title, {html: message, width: "400px"});
			return false;
		}

		// set current form
		// * closest .add_to_cart_form to $configuratorLink
		// * defined form-id in data-attributes of $configuratorLink (this has priority)
		ACC.configurator.properties.$configuratorCurrentForm = $configuratorLink.closest('form.add_to_cart_form');
		var dataFormId = $configuratorLink.data(ACC.configuratorConfig.dataAttrNames.dataConfiguratorFormId);
		if (dataFormId && dataFormId !== '') {
			ACC.configurator.properties.$configuratorCurrentForm = $('form#' + $.escapeSelector(dataFormId));
		}

		// validate amount for configurator
		ACC.configurator.properties.amount = $configuratorLink.closest('form').find('input[name=value]').val();
		if (!ACC.configuratorValidation.validateAmount(ACC.configurator.properties.amount, $configuratorLink)) {
			return false;
		}

		ACC.configurator.openLightBox();
	},

	openLightBox: function () {
		var colorboxConfig = {
			title: '<div class="headline"><span class="headline-text">' + ACC.configurator.properties.popupTitle + '</span></div>',
			inline: true,
			href: ACC.configuratorConfig.selectors.popupID,
			maxWidth: "100%",
			width: '100%',
			opacity: 0.7,
			escKey: false,
			overlayClose: false,
			closeButton: false,
			scrolling: false,
			className: 'configurator-overlay',
			onComplete: function () {
				// start initialization when colorbox (lightbox) is ready
				ACC.configurator.init();

				ACC.configuratorActions.loadConfiguration(ACC.configurator.properties.productCode, ACC.configurator.properties.entryNumber, ACC.configurator.properties.orderCode, ACC.configurator.properties.orderEntryNumber, ACC.configurator.properties.amount);
			}
		};

		// open colorbox
		$.colorbox(colorboxConfig);
		$.extend(ACC.configuratorConfig, {'confirmationQuestion': $('.card-configurator').data('confirmationQuestion')});
	},

	init: function () {
		ACC.configurator.cleanupConfigurator();
		ACC.configurator.createEventHandler();
		ACC.configurator.getDesignJSON();
		ACC.configurator.cancelConfiguratorPopup();

		// add close btn ueacs
		$('.configurator-overlay #cboxContent').append(ACC.configuratorConfig.closeBtnSnippet);
	},

	//ueacs
	cancelConfiguratorPopup: function () {

		var visibleCancelPopup;

		$(document).on('click.configurator', '.configurator-closeBtn', function (e) {
			$('#cancelConfigurationPopup').popup({
				closebutton: true,
			});

			$('#cancelConfigurationPopup').popup('show');
			$('#cancelConfigurationPopup #cboxContent .configurator-closeBtn').last().remove();
			$('.popup_close').remove();
			visibleCancelPopup = true;
		});

		$(document).on('#cancelConfigurationPopup keyup.configurator', function (e) {
			if (e.keyCode === 27 && !visibleCancelPopup) {
				$('#cancelConfigurationPopup').popup({
					closebutton: true,
				});

				$('#cancelConfigurationPopup').popup('show');
				$('#cancelConfigurationPopup #cboxContent .configurator-closeBtn').last().remove();
				$('.popup_close').remove();
				visibleCancelPopup = true;
			}
			else
			{
				visibleCancelPopup = false;
			}
		});

	},


	cleanupConfigurator: function () {
		// remove all events
		ACC.configurator.removeEventHandler();

		// destroy croppie plugin
		ACC.configurator.destroyCroppie();
		ACC.configurator.destroyLogoCroppie();

		/*****************************************************************/
		/************   cleanup elements                ******************/
		/*****************************************************************/
		// hide checks
		ACC.configuratorActions.hideAllCheckIcons();

		// remove logo
		$(ACC.configuratorConfig.selectors.logoImgElement).removeAttr('src');

		// reset upload buttons
		$(ACC.configuratorConfig.selectors.hiddenUploadBackgroundBtn).val('');
		$(ACC.configuratorConfig.selectors.hiddenUploadLogoBtn).val('');

		// hide tooltips
		ACC.global.hideErrorMsgTooltip($(ACC.configuratorConfig.selectors.uploadBackgroundBtn));
		ACC.global.hideErrorMsgTooltip($(ACC.configuratorConfig.selectors.uploadLogoBtn));

		$(ACC.configuratorConfig.selectors.sliderLabelInfoIcon).removeClass('active');

		// reset tempFormDataObject
		ACC.configurator.properties.tempFormDataObject = JSON.parse(JSON.stringify(ACC.configuratorConfig.cleanTempFormDataObject));

		// remove close btn
		$('.configurator-overlay .configurator-closeBtn').remove();

		// hide logo, background image and amount
		if ($(ACC.configuratorConfig.selectors.croppieBoundary).length) {
			$(ACC.configuratorConfig.selectors.croppieBoundary).addClass('faded-out');
		}
		$(ACC.configuratorConfig.selectors.cardValue).hide();
		$(ACC.configuratorConfig.selectors.logoWrapper).addClass('faded-out');

		// collapse design accordion
		$(ACC.configuratorConfig.selectors.configuratorDesignsList).collapse('hide');

		// hide settings section and reset mode
		$('.card-configurator-settings').addClass('card-configurator-settings--invisible');
		$(ACC.configuratorConfig.selectors.popupID).removeClass('advanced simple');
	},

	createEventHandler: function () {
		/*****************************************************************/
		/************   Event handling for configurator ******************/
		/*****************************************************************/

		var $hiddenUploadBackgroundBtn = $(ACC.configuratorConfig.selectors.hiddenUploadBackgroundBtn);
		var $hiddenUploadLogoBtn = $(ACC.configuratorConfig.selectors.hiddenUploadLogoBtn);

		$(document).on('change.configurator', ACC.configuratorConfig.selectors.hiddenUploadBackgroundBtn, function () {
			ACC.configuratorActions.uploadBackgroundImage();
		});

		$(document).on('click.configurator', ACC.configuratorConfig.selectors.uploadBackgroundBtn, function () {
			$hiddenUploadBackgroundBtn.click();
		});

		$(document).on('change.configurator', ACC.configuratorConfig.selectors.hiddenUploadLogoBtn, function () {
			ACC.configuratorActions.uploadLogo();
		});

		$(document).on('click.configurator', ACC.configuratorConfig.selectors.uploadLogoBtn, function () {
			$hiddenUploadLogoBtn.click();
		});

		$(document).on('change.configurator', ACC.configuratorConfig.selectors.checkboxShowValue, function (e) {
			if ($(e.target).is(":checked")) {
				if (ACC.configurator.properties.tempFormDataObject.showCardValue !== true) {
					// configuration was changed
					ACC.configurator.properties.configurationChanged = true;
				}

				$(ACC.configuratorConfig.selectors.cardValue).removeClass('faded-out');
				ACC.configurator.properties.tempFormDataObject.showCardValue = true;
			}
			else {
				if (ACC.configurator.properties.tempFormDataObject.showCardValue !== false) {
					// configuration was changed
					ACC.configurator.properties.configurationChanged = true;
				}

				$(ACC.configuratorConfig.selectors.cardValue).addClass('faded-out');
				ACC.configurator.properties.tempFormDataObject.showCardValue = false;
			}
		});

		$(document).on('click.configurator', ACC.configuratorConfig.selectors.addToCartBtn, function (e) {
			ACC.configuratorActions.addConfigurationToCart(e);
		});


		$(document).on('click.configurator', ACC.configuratorConfig.selectors.configuratorDesignsListImage, function (e) {
			console.log(e.target.src);
			ACC.configuratorActions.replaceBackgroundImage(e);
			//ECRGKY-934
			$(ACC.configuratorConfig.selectors.configuratorDesignsList).collapse('hide');
		});

		// drop functionality
		$(document).on({
			'dragover.configurator dragenter.configurator': function (e) {
				// hide current image
				if ($(ACC.configuratorConfig.selectors.croppieBoundary).length) {
					$(ACC.configuratorConfig.selectors.croppieBoundary).addClass('faded-out');
				}

				$(this).addClass('highlighted');
				$(ACC.configuratorConfig.selectors.cardValue).addClass('faded-out');
				$(ACC.configuratorConfig.selectors.logoWrapper).addClass('faded-out');

				e.preventDefault();
				e.stopPropagation();
			},
			'dragleave.configurator dragexit.configurator': function (e) {
				$(this).removeClass('highlighted');

				// show new image
				if ($(ACC.configuratorConfig.selectors.croppieBoundary).length) {
					$(ACC.configuratorConfig.selectors.croppieBoundary).removeClass('faded-out');
					$(ACC.configuratorConfig.selectors.cardValue).removeClass('faded-out');
					$(ACC.configuratorConfig.selectors.logoWrapper).removeClass('faded-out');
				}
			},
			'drop.configurator': function (e) {
				if (ACC.configurator.properties.mode == 'advanced') {
					ACC.configuratorActions.dropBackgroundImage(e, $(this));
				}
				else if (ACC.configurator.properties.mode == 'simple') {
					ACC.configuratorActions.dropLogo(e, $(this));
				}
				$(ACC.configuratorConfig.selectors.croppieBoundary).removeClass('faded-out');
				$(ACC.configuratorConfig.selectors.cardValue).removeClass('faded-out');
				$(ACC.configuratorConfig.selectors.logoWrapper).removeClass('faded-out');
			}
		}, ACC.configuratorConfig.selectors.droppableArea);

		// adjust height/width of the colorbox when the design section is collapsed or shown
		$(document).on('shown.bs.collapse.configurator hidden.bs.collapse.configurator', ACC.configuratorConfig.selectors.configuratorDesignsList, function () {
			var currentScrollPos = $(document).scrollTop();
			ACC.configuratorActions.resizeColorbox();
			$(document).scrollTop(currentScrollPos);
		});


		/*****************************************************************/
		/************   Event handling for croppie      ******************/
		/*****************************************************************/
		$(ACC.configuratorConfig.selectors.sliderLabelInfoIcon + '[data-toggle="popover"]').popover();
		$(document).on('update.croppie', ACC.configuratorConfig.selectors.droppableArea, function (ev, cropData) {
			clearTimeout(ACC.configurator.properties.updateTimerId),
				(ACC.configurator.properties.updateTimerId = setTimeout(function () {
					ACC.configurator.properties.configurationChanged = true;
					ACC.configurator.properties.backgroundImageReplaced = true;
					ACC.configurator.properties.logoReplaced = true;
				}, 250));
		});
		/*****************************************************************/
		/************   Event handling for colorbox     ******************/
		/*****************************************************************/
		// create own close events for clicking on close btn and pressing esc key
		$(document).on('click.configurator', '.configurator-closeBtn', function (e) {
			e.preventDefault();//ueacs
			//ACC.configurator.showConfirmation();
		});

		// store scrollTop position, if colorbox was repositioned by scroll.colorbox handler
		$(document).on("colorbox_reposition_onscroll.configurator", function (event, data) {
			ACC.configurator.properties.scrollTop = data.scrollTop;
		});

		// update all positions in case of browser zoom or changed viewport dimensions (height/width)
		$(window).on('resize.configurator', function () {
			ACC.configuratorActions.resizeColorbox();
			ACC.configurator.resetCroppieImageAllignment();
		});
	},

	removeEventHandler: function () {
		$(document).off('.configurator');
		$(document).off('.configurator .croppie', ACC.configuratorConfig.selectors.droppableArea);
		$(window).off('.configurator');
	},

	showConfirmation: function () {
		if (confirm(ACC.configuratorConfig.confirmationQuestion)) {
			ACC.configurator.cleanupConfigurator();
			$.colorbox.close();
		}
	},

	resetCroppieImageAllignment: function(){
		$(ACC.configuratorConfig.selectors.croppieBoundary).height($(ACC.configuratorConfig.selectors.configuratorContent).height());

		// zoom for background image will be reset so that the image is still bigger than the mask
		if ($(ACC.configuratorConfig.selectors.droppableArea).hasClass('croppie-container')) {
			$(ACC.configuratorConfig.selectors.droppableArea).croppie('bind',
				{
					url : ACC.configurator.properties.tempFormDataObject.backgroundImage.src,
					zoom: 0,
				}).then(function(){
				$(ACC.configuratorConfig.selectors.droppableArea).croppie('setZoom', 0)
			})}

		ACC.configurator.resetCroppieLogoImageAlligment()
	},
	resetCroppieLogoImageAlligment: function(){
		if ($(ACC.configuratorConfig.selectors.logoCroppieArea).hasClass('croppie-container')) {
			$(ACC.configuratorConfig.selectors.logoCroppieArea).croppie('bind',
				{
					url: ACC.configurator.properties.tempFormDataObject.logo.src,
				}).then(function(){
				$(ACC.configuratorConfig.selectors.logoCroppieArea).croppie('setZoom', ACC.configuratorConfig.properties.logoMinZoom);
			});
		}

	},

	initCroppie: function (backgroundImageSrc, coordinates, zoom) {
		var customImageSrc = backgroundImageSrc;
		var imageMaskSrc = $(ACC.configuratorConfig.selectors.imageMask).attr('src');

		// destroy croppie in order to have a basic setting........
		ACC.configurator.destroyCroppie();

		var basic = $(ACC.configuratorConfig.selectors.droppableArea).croppie({});

		var croppieConfig = {};

		if (coordinates == undefined && zoom == undefined) {
			croppieConfig = {
				url: customImageSrc,
				zoom: 0
			};

		} else {
			croppieConfig = {
				url: customImageSrc,
				points: coordinates,
				zoom: zoom
			};
		}

		var croppieInitPromise = basic.croppie('bind', croppieConfig);
		// croppie is now initialized
		croppieInitPromise.then(function () {
			var minZoom = $('.cr-slider').attr('min');
			var maxZoom = $('.cr-slider').attr('max');

			// calc critical croppie zoom factor
			var zoomFactor = (ACC.configurator.properties.tempFormDataObject.backgroundImage.originalWidth * ACC.configuratorConfig.properties.backgroundImageQualityValidationBuffer) / ACC.configuratorConfig.properties.backgroundImageMinWidth;
			var criticalCroppieZoomFactor = (zoomFactor * ACC.configuratorConfig.properties.configuratorMaskWidth) / ACC.configurator.properties.tempFormDataObject.backgroundImage.originalWidth;

			// check if maxZoom needs to be adapted
			if (minZoom && maxZoom && (minZoom < criticalCroppieZoomFactor)) {
				// set maxZoom to criticalCroppieZoomFactor + 25%
				$('.cr-slider').attr('max', criticalCroppieZoomFactor * 1.25);
			}
		});


		$(ACC.configuratorConfig.selectors.croppieViewport).css({'background': 'url(' + imageMaskSrc + ')'});

		// move croppie slider to settings area
		if ($(ACC.configuratorConfig.selectors.droppableArea + ' .cr-slider-wrap').length) {
			ACC.configurator.properties.sliderObject = $(ACC.configuratorConfig.selectors.droppableArea + ' .cr-slider-wrap').detach();
			ACC.configurator.properties.sliderObject.appendTo($('.card-configurator-settings-section').first());
		}

		if(backgroundImageSrc === ""){
			ACC.configurator.properties.sliderObject.css({'visibility': 'hidden'});
		}

		// workaround for tablet
		$(ACC.configuratorConfig.selectors.croppieBoundary).height($(ACC.configuratorConfig.selectors.configuratorContent).height());
	},

	initLogoCroppie:function(logoImage){
		var data = ACC.configurator.properties.responseData;
		var logoHeight = ACC.configuratorConfig.properties.configuratorMaskWidth * data.configSettings.DESIGN.logoH;
		var logoWidth = ACC.configuratorConfig.properties.configuratorMaskWidth * data.configSettings.DESIGN.logoW;

		var customImageSrc = logoImage.src;
		var zoom = Math.min(logoWidth / logoImage.width, logoHeight / logoImage.height);
		ACC.configuratorConfig.properties.logoMinZoom = zoom;
		ACC.configurator.destroyLogoCroppie();

		var logoCroppie =$(ACC.configuratorConfig.selectors.logoCroppieArea).croppie(
			{
				enforceBoundary:false,
				zoom: zoom,
			viewport: {
				width: logoWidth ,
				height: logoHeight,
			},
			});

		logoCroppie.croppie('bind',{
			url: customImageSrc,
			zoom: zoom,
		}).then(function(){
			$(ACC.configuratorConfig.selectors.logoCroppieArea).croppie('setZoom', ACC.configuratorConfig.properties.logoMinZoom)
		});

		$('.cr-boundary').css({'background-color': 'white'});

		if ($(ACC.configuratorConfig.selectors.logoCroppieArea + ' .cr-slider-wrap').length) {
			$(ACC.configuratorConfig.selectors.logoCroppieArea + ' .cr-slider-wrap .cr-slider').attr('min',0.01)
			ACC.configurator.properties.logoSliderObject = $(ACC.configuratorConfig.selectors.logoCroppieArea + ' .cr-slider-wrap').detach();
			ACC.configurator.properties.logoSliderObject.appendTo($('.card-configurator-settings-section').eq(1));
		}

		if(customImageSrc === undefined) {
			ACC.configurator.properties.logoSliderObject.css({'visibility': 'hidden'});
		}
	},

	destroyCroppie: function () {
		// move croppie slider back to original position so that croppie can be destroyed
		if ($('.card-configurator-settings-section--backgroundImage .cr-slider-wrap').length) {
			ACC.configurator.properties.sliderObject.appendTo($(ACC.configuratorConfig.selectors.droppableArea));
		}

		$(ACC.configuratorConfig.selectors.droppableArea).croppie('destroy');
	},
	destroyLogoCroppie:function(){
		if ($('.card-configurator-settings-section--logo .cr-slider-wrap').length) {
			ACC.configurator.properties.logoSliderObject.appendTo($(ACC.configuratorConfig.selectors.logoCroppieArea));
		}

		$(ACC.configuratorConfig.selectors.logoCroppieArea).croppie('destroy');
	},


	getDesignJSON: function () {
		var jsonPath = $(ACC.configuratorConfig.selectors.configuratorDesignsList).data(ACC.configuratorConfig.dataAttrNames.designTemplatesIndexJson);

		$.getJSON(jsonPath, function (data) {
			ACC.configurator.createDesignList(data)
		});
	},

	/**
	 * creates the items (images + title) from the JSON file.
	 * @param data JSON
	 */
	createDesignList: function (data) {
		var categories = data.categories;
		var $designList = $(ACC.configuratorConfig.selectors.configuratorDesignsList);
		var designTemplatesUrl = $designList.data(ACC.configuratorConfig.dataAttrNames.designTemplatesUrl);

		// clone one list item and save it as a property, remove it afterwards from the DOM
		if (ACC.configurator.properties.designListItem === undefined) {
			ACC.configurator.properties.designListItem = $(ACC.configuratorConfig.selectors.configuratorDesignsListItem).remove();
		}
		else {
			$(ACC.configuratorConfig.selectors.configuratorDesignsListItem).remove();
		}

		categories.forEach(function (category) {
			// create new list item from list item template
			var $listItem = ACC.configurator.properties.designListItem.clone();
			// create list item image template from list item and remove it afterwards from the DOM
			var $listItemImageTemplate = $listItem.find(ACC.configuratorConfig.selectors.configuratorDesignsListImage).remove();

			// set the title for the current category
			$listItem.find(ACC.configuratorConfig.selectors.configuratorDesignsListTitle).text(category.title);

			// add all images of the current category
			var images = category.images;
			images.forEach(function (imageSrc) {
				var $listItemImage = $listItemImageTemplate.clone();
				$listItemImage.attr('src', designTemplatesUrl + imageSrc);
				$listItem.append($listItemImage);
			});

			// finally add the manipulated list item
			$designList.append($listItem);
		});
	}
};
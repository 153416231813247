ACC.register = {
	_autoload: [
		["bindToggleDeliveryAddress", $('#b2bgkRegisterForm').length > 0],
		["bindTabShownToggleButtons", $('#b2bgkRegisterForm').length > 0],
		["bindTabSwitchInterceptor", $('#b2bgkRegisterForm').length > 0],
		["bindToggleAddressLegalType", $('#b2bgkRegisterForm').length > 0],
		["initRegisterForm", $('#b2bgkRegisterForm').length > 0],
		["bindTogglePostbox", $('#b2bgkRegisterForm').length > 0]
	],

	/**
	 * page load handler
	 */
	initRegisterForm: function () {
		var $form = $('#b2bgkRegisterForm');
		// select a tab
		if (!ACC.register.markTabsWithErrorsAndSelectFirst()) {
			// activate first tab
			$form.find('a[data-toggle="tab"]').first().click();
		}
		ACC.register.prepareAddressFields();
	},

	markTabsWithErrorsAndSelectFirst: function () {
		var $form = $('#b2bgkRegisterForm');

		// remove error class from tabs
		$form.find('a[data-toggle="tab"]').removeClass('has-error');

		// set active tab-pane in case of validation errors
		var $wrongFields = $form.find('.form-group.has-error');
		if ($wrongFields.length) {
			var firstErrorPaneID = $wrongFields.first().closest('.tab-pane').attr('id');
			$form.find('a[data-toggle="tab"][href="#' + firstErrorPaneID + '"]').click();

			// mark all invalid tabs
			$wrongFields.each(function () {
				var errorPaneID = $(this).closest('.tab-pane').attr('id');
				$form.find('a[data-toggle="tab"][href="#' + errorPaneID + '"]').addClass('has-error');
			});
		}
		return Boolean($wrongFields.length);
	},

	prepareAddressFields: function () {
		var $form = $('#b2bgkRegisterForm');
		// prepare visible fields for initially selected address legal types
		var $addressLegalTypeRadios = $form.find('.js-address-legal-type-toggle input[type="radio"]:checked');
		$addressLegalTypeRadios.each(function (index, element) {
			ACC.register.addressLegalTypeHandler($(element));
		});
	},

	/**
	 * show/hide content depending on param
	 * @param isSameAsBillingAddress
	 */
	deliveryAddressHandler: function (isSameAsBillingAddress) {
		var $form = $('#b2bgkRegisterForm');
		// toggle buttons
		$form.find('.js-register-to-delivery').toggle(!isSameAsBillingAddress);
		$form.find('.js-register-submit').toggle(isSameAsBillingAddress);
		// toggle info text and form fields
		$form.find('.js-register-delivery-form-fields').toggle(!isSameAsBillingAddress);
		$form.find('.js-register-delivery-is-same-text').toggle(isSameAsBillingAddress);
	},

	/**
	 * set additional class to tab-pane for addresses legal types
	 * @param $radioElement
	 */
	addressLegalTypeHandler: function ($radioElement) {
		var $form = $radioElement.closest('form'),
			$addressPanes = $form.find('#billingaddress.tab-pane, #deliveryaddress.tab-pane'),
			selectedValue = $form.find('input[type="radio"][name="' + $radioElement.attr('name') + '"]:checked').val();
		$addressPanes.toggleClass('address-legal-type-person', selectedValue === 'PRIVATE');
	},

	/**
	 * handler after tab is shown
	 */
	bindTabShownToggleButtons: function () {
		var $form = $('#b2bgkRegisterForm');

		$(document).on('shown.bs.tab.tabShownToggleButtons', 'a[data-toggle="tab"]', function (event) {
			var $shownTab = $(event.target),
				targetPaneSelector = $shownTab.attr('data-target') || $shownTab.attr('href');

			// show/hide buttons
			if (targetPaneSelector === '#general') {
				$form.find('.js-register-to-billing').show();
				$form.find('.js-register-submit').hide();
			}
			else if (targetPaneSelector === '#billingaddress') {
				var $checkbox = $form.find('.js-toggle-delivery-address'),
					isSameAsBilling = $checkbox.is(':checked');
				ACC.register.deliveryAddressHandler(isSameAsBilling);
			}
			else if (targetPaneSelector === '#deliveryaddress') {
				$form.find('.js-register-submit').show();
			}
		});

	},
	unbindTabShownToggleButtons: function () {
		$(document).off('.tabShownToggleButtons');
	},

	bindTabSwitchInterceptor: function () {
		$('.tab-content').on('click.tabSwitchInterceptor', 'a[data-forward="tab"]', function (event) {
			var $this = $(event.currentTarget),
				tabID = $this.attr('data-target') || $this.attr('href'),
				$tab = $('[data-toggle="tab"][data-target="' + tabID + '"], [data-toggle="tab"][href="' + tabID + '"]'),
				activeTabId = $('#b2bgkRegisterForm .nav-tabs .active a').attr('href');

			event.stopPropagation();
			event.preventDefault();

			$.when(ACC.register.validateForm(activeTabId)).then(
				function () {
					// if form is valid => go to next tab
					$tab.click();
				},
				function () {
				}
			);
		});
	},

	unbindTabSwitchInterceptor: function () {
		$('.tab-content').off('.tabSwitchInterceptor');
	},


	/**
	 * handle change on checkbox for same delivery-address as billing-address
	 */
	bindToggleDeliveryAddress: function () {
		$(document).on('click.toggleDeliveryAddress', '.js-toggle-delivery-address', function (event) {
			var $checkbox = $(event.currentTarget),
				isSameAsBilling = $checkbox.is(':checked');
			ACC.register.deliveryAddressHandler(isSameAsBilling);
		});
	},
	unbindToggleDeliveryAddress: function () {
		$(document).off('.toggleDeliveryAddress');
	},

	/**
	 * handle change on radios for address-legal-types
	 */
	bindToggleAddressLegalType: function () {
		$(document).on('change.toggleAddressLegalType', '.js-address-legal-type-toggle input[type="radio"]', function (event) {
			var $radio = $(event.currentTarget);
			ACC.register.addressLegalTypeHandler($radio);
		});
	},
	unbindToggleAddressLegalType: function () {
		$(document).off('.toggleAddressLegalType');
	},

	/**
	 * partially validate the form
	 * - only take previous tabs and active tab into account for validation
	 * - replaces whole tab contents and global messages
	 * @param activeTabSelector
	 * @returns {*}
	 */
	validateForm: function (activeTabSelector) {
		var $jqDeferred = $.Deferred();
		var $pageForm = $('#b2bgkRegisterForm');
		var requestUrl = $pageForm.attr('action');
		var formData = [$pageForm.serialize(), 'isValidationOnly=true'].join('&');

		$.post(requestUrl, formData).done(function (response) {
			var $responseForm = $(response).find('#b2bgkRegisterForm');
			var $responseTabsToCheck = $responseForm.find(activeTabSelector).prevAll('.tab-pane').addBack(); // active tab element and all previous siblings
			var formHasErrors = false;

			// handle previous tabs and active tab
			$responseTabsToCheck.each(function (index, responseTabPane) {
				var $responseTabPane = $(responseTabPane);
				var $pagePane = $pageForm.find('#' + $responseTabPane.attr('id'));
				var tabHasErrors = Boolean($responseTabPane.find('.form-group.has-error').length);

				// always replace the page tabPane content with response tabPane content
				// to show or remove validation error messages
				$pagePane.html($responseTabPane.html());

				// restore password-strength
				var $passwordFields = $pagePane.find('.password-strength');
				if ($passwordFields.length) {
					ACC.global.passwordStrength($passwordFields);
					$passwordFields.trigger('keyup');
				}

				formHasErrors = formHasErrors || tabHasErrors;
			});

			// handle global messages
			var $responseGlobalMessages = $(response).find(".global-alerts").first();
			var $currentGlobalMessages = $(".global-alerts").first();
			if (formHasErrors) {
				// replace or add global messages from response to page
				if ($currentGlobalMessages.length) {
					$currentGlobalMessages.replaceWith($responseGlobalMessages);
				}
				else {
					$("#skip-to-content").first().after($responseGlobalMessages);
				}
			}
			else {
				// remove global messages from page
				$currentGlobalMessages.remove();
			}

			// re-init form
			ACC.register.markTabsWithErrorsAndSelectFirst();
			ACC.register.prepareAddressFields();

			// handle promise
			if (formHasErrors) {
				$jqDeferred.reject();
			}
			else {
				$jqDeferred.resolve();
			}
		});

		return $jqDeferred.promise();
	},

	bindTogglePostbox: function () {
		var selectors = {
			copy: [
				{from: 'input[name=billingAddressPostalCode]', to: 'input[name=billingAddressPoboxPostalcode]'},
				{from: 'input[name=billingAddressTown]', to: 'input[name=billingAddressPoboxTown]'}
			]
		};
		ACC.address.bindTogglePostbox(selectors);
	}
};

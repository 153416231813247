ACC.productcarousel = {
	_autoload: [
		"init",
		"bindAmountContainerPosition",
		"bindNumberInputCopyToClonedTiles"
	],

	init: function () {
		var $productCarousel = $('.js-productcarousel');

		var slickConfig = {
			speed: 300,
			arrows: true,
			slidesToShow: 4,
			slidesToScroll: 1,
			swipeToSlide: true,
			responsive: [
				{
					breakpoint: 1400,
					settings: {
						slidesToShow: 3
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 640,
					settings: {
						slidesToShow: 1
					}
				}
			]
		};

		// init slick slider
		$productCarousel.slick(slickConfig);
	},

	/**
	 * repositioning of card-amount-containers inside a product carousel
	 * - "setPosition" is the most fired event of the slick-slider (after init, after swipe/slide, ...)
	 */
	bindAmountContainerPosition: function () {
		$('.js-productcarousel').on("setPosition.carouselAmountContainerPosition", function (event, slick) {
			slick.$slider.find(".js-amount-overlay").each(ACC.product.setAmountContainerLayoutAndStyle);
		});
	},
	unbindAmountContainerPosition: function () {
		$('.js-productcarousel').off(".carouselAmountContainerPosition");
	},

	/**
	 * copy amount and quantity to cloned slides inside a product carousel
	 */
	bindNumberInputCopyToClonedTiles: function () {
		$('.js-productcarousel').on('numberInputValidation.copyToCloned', 'input.js-number, input.js-price', function (event, isValid) {
			if (isValid) {
				var $carousel = $(event.delegateTarget),
					$input = $(event.currentTarget),
					inputName = $input.attr('name'),
					$form = $input.closest('form'),
					productId = $form.find('[name="productCodePost"]').last().val(),
					$clonedInputsOfThisProductWithDifferentValue;
				$clonedInputsOfThisProductWithDifferentValue = $carousel.find('[name="productCodePost"][value="' + productId + '"]')
					.closest('form').not($form)
					.find('[name="' + inputName + '"]')
					.filter(function (index, input) {
						return $(input).val() !== $input.val();
					});
				$clonedInputsOfThisProductWithDifferentValue.val($input.val());
				$clonedInputsOfThisProductWithDifferentValue.trigger('change');
			}
		});
	},
	unbindNumberInputCopyToClonedTiles: function () {
		$('.js-productcarousel').off(".copyToCloned");
	}
};
ACC.order = {

	_autoload: [
	    "initPreviewColorboxLink",
	    "backToOrderHistory",
	    "bindMultidProduct",
		"bindBackLink",
	    "submitOrderEntry"
	],

	initPreviewColorboxLink: function () {
		var config = $.extend({}, ACC.colorbox.config, {photo: true, title: false});
		$('.card-preview-link').colorbox(config);
	},

	backToOrderHistory: function(){
		$(".orderBackBtn > button").on("click", function(){
			var sUrl = $(this).data("backToOrders");
			window.location = sUrl;
		});
	},

	submitOrderEntry: function(){
		$(document).on("click", "#reviseOrderEntryButton", function (e) {
			var $orderEntryRevisionForm = $('#orderEntryRevisionForm');

			e.preventDefault();

			if(!$orderEntryRevisionForm.length) {
				return;
			}

			var isLetterShopEnabled = $orderEntryRevisionForm.data('isLettershopEnabled');

			if(isLetterShopEnabled) {
				// entry form validation
				// check addresslist (filePk when file was already uploaded, uploadButton value for selected files)
				var addresslistUploaded = false;
				var filePk = $orderEntryRevisionForm.find('.lettershop-uploadform-uploadAddresslist .js-lettershop-fileItem__editFileButton').data('filePk');
				var uploadButtonValue = $orderEntryRevisionForm.find('.lettershop-uploadform-uploadAddresslist .js-lettershop-uploadButtonAddresslist').val();

				if(filePk !== '' || uploadButtonValue !== '') {
					console.log('addresslist selected');
					addresslistUploaded = true;
				}

				// check coverletters (filePk when file was already uploaded, uploadButton value for selected files)
				var coverlettersUploaded = false;
				var $coverletters = $('.lettershop-uploadform-uploadCoverLetters .lettershop-fileItem');
				$coverletters.each(function(){
					var $ref = $(this);
					filePk = $ref.find('.js-lettershop-fileItem__editFileButton').data('filePk');
					uploadButtonValue = $ref.find('.js-lettershop-uploadButtonCoverletter').val();

					if(filePk !== '' || uploadButtonValue !== '') {
						coverlettersUploaded = true;
					}
				});

				// submit form when at least one addresslist and one coverletter was selected/uploaded
				if(addresslistUploaded && coverlettersUploaded) {
					$orderEntryRevisionForm.submit();
				}
				else {
					$('.alert.alert-danger--missing-files').removeClass('hidden');
				}
			}
			else {
				$orderEntryRevisionForm.submit();
			}
		});
	},
	
	bindMultidProduct: function ()
	{
		// link to display the multi-d grid in read-only mode
		$(document).on("click",'.js-show-multiD-grid-in-order', function (event){
			ACC.multidgrid.populateAndShowGrid(this, event, true);
			return false;
		});

		// link to display the multi-d grid in read-only mode
		$(document).on("click",'.showMultiDGridInOrderOverlay', function (event){
			ACC.multidgrid.populateAndShowGridOverlay(this, event);
		});
	},

	bindBackLink: function() {
		var backLinkSelector = '.js-back-link';

		// show alert only on order entry edit page
		if($('body').hasClass('page-orderEntryEdit')) {
			$(document).on('click', backLinkSelector, function (e) {
				var alertMsg = $(e.currentTarget).data('alertMsg');

				if (!confirm(alertMsg)) {
					e.preventDefault();
				}
			});
		}
	}
};